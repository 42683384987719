@import '@styles/common/variables';
@import '@styles/common/mixins';

.employeePayoutsPage {
    display: flex;
    justify-content: center;
    padding: 1rem;

    .employeePayoutsContainer {
        width: min(600px, 100vw);
        background-color: $white;
        border: 1px solid $neutral-10;
        box-shadow: 0px 2px 80px 0px rgba(0, 0, 0, 0.06);
        padding: rem(24);
        margin: 0 auto;

        @media (max-width: 768px) {
            padding: rem(16);
        }

        @media (max-width: 480px) {
            padding: rem(8);
        }

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            :global {
                .ant-input,
                .ant-input-password,
                .ant-input-number-input,
                .ant-input-number {
                    width: 100%;
                    height: 48px;
                    @include text-style(rem(14), 400, rem(24), $primary-text);
                }

                .ant-input-password {
                    .ant-input {
                        height: revert;
                    }
                }
            }
        }

        .stepContainer {
            margin-bottom: rem(16);
            width: 100%; // Make sure it takes the full width of the container
            max-width: 512px; // Limit the step container width on larger screens
        }

        .formWrapper {
            padding: rem(16) 0;
            width: 100%; // Full width of container to avoid layout breaking

            @media (max-width: 768px) {
                padding: rem(8) 0;
            }

            @media (max-width: 577px) {
                :global {
                    .ant-row {
                        width: 100%;
                        margin-right: 0 !important;
                        margin-left: 0 !important;

                        .ant-col {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                        }
                    }
                }
            }
        }

        :global {
            .ant-input-number-input-wrap {
                height: 100%;
                display: flex;
                align-items: center;
            }

            .ant-form-item-required {
                font-weight: 400;
            }

            .ant-input,
            .ant-select-selector {
                max-height: rem(41);
                min-height: rem(41);
            }
        }
    }
}
