@import '/src/styles/common/variables';

.application-container {
    background-color: $neutral-10;
    border-radius: rem(16);
    padding: rem(24);
    max-height: rem(450);
    overflow: auto;

    .title {
        font-size: rem(24);
        line-height: rem(32);
        font-weight: 700;
        color: $neutral-500;
    }
    .application-list-container {
        margin-top: rem(24);
    }

    .application-item-container {
        .application-item {
            display: flex;
            justify-content: center;
            background-color: $white;
            border-radius: rem(8);
            padding: rem(24);
            height: 100%;
            .avatar {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 56px;

                .avatar-image {
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                }
            }

            .title {
                margin-top: rem(12);
                margin-bottom: rem(2);
                font-size: rem(16);
                font-weight: 700;
                color: $primary-text;
            }
            .description {
                font-size: rem(14);
                font-weight: 400;
                color: $gray-500;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .application-detail-button {
                margin-top: rem(28);
                background-color: $secondary;
                color: $primary;
                font-size: rem(14);
                line-height: rem(18);
                font-weight: 400;
                padding: 0 rem(16);
                height: rem(44);
            }
            .detail-application {
                text-align: center;
            }
        }
    }
}
