@import '/src/styles/common/variables';

.introduce-container {
    border: 1px solid #e9eaec;
    border-radius: 16px;
    .welcome-col {
        padding-left: 32px;
        padding-right: 32px;
        .welcome-text {
            color: $neutral-500;
            font-size: rem(40);
            line-height: rem(52);
            font-weight: 700;
            margin-bottom: rem(8);
        }

        .description-text {
            color: $neutral-60;
            font-size: rem(16);
            line-height: rem(24);
            font-weight: 400;
        }
    }
    .image-col {
        position: relative;
        .image-dashboard {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 16px 0 0;
        }

        .ant-image:not(:has(div.banner-dashboard)) {
            margin-right: rem(83);
        }
    }
}
