label.product-header-section {
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
    .anticon {
        margin-right: 20px;
        svg {
            font-size: 20px;
        }
    }
}

.product-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
}

.product-list-item {
    height: 100%;
    overflow: auto;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: -20px;
    margin-right: -20px;
}
