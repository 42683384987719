.avatar-upload {
    border-radius: 50%;
    border: 4px solid #ffffff;
    margin-bottom: 42px;
    .ant-upload-select-picture-card {
        border-radius: 50%;
        border: 4px solid #ffffff;
        box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%), 0px 4px 6px -2px rgb(16 24 40 / 3%);
        box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%), 0px 4px 6px -2px rgb(16 24 40 / 3%);
    }
    .upload {
        width: 100%;
        .placeholder {
            border: none;
            box-shadow: none;
            background-color: transparent;
            image {
                width: 32px;
            }
        }
    }
    .btn-add-photo {
        position: absolute;
        top: 52px;
        left: 11px;
    }

    .border-circle {
        border-radius: 50%;
    }
}
