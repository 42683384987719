@import '../../../styles/common/variables';

.onboarding-layout-step-three {
    background: #fff url('~/public/images/bg.jpg') no-repeat center bottom fixed !important;
    background-size: cover !important;
    .onboarding-container {
        margin: auto;
        max-width: 460px;
        border-radius: 10px;

        .ant-card {
            background: #fff;
            padding: 20px;
            .ant-card-head {
                padding: 0;
            }
            .ant-card-body {
                padding: 0;
                .org-info {
                    text-align: center;
                }
            }
        }
        .ant-card-head-title {
            white-space: pre-wrap;
            text-align: center;
        }

        .title {
            color: #22313f;
            font-size: 40px;
            margin: 0px 0 5px;
        }

        .desc {
            color: #7a838c;
            font-size: 16px;
            font-weight: 400;
        }

        .ant-btn-primary {
            background: $primary;
            border: 0;
        }
    }

    .skip-add-payment {
        display: flex;
        justify-content: center;
        color: $primary;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
}