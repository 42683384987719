@import '/src/styles/common/variables';

.payout-notification {
    padding: rem(12) rem(20);
    margin-bottom: rem(16);
    border-radius: rem(8);

    &.restricted,
    &.error {
        color: $negative-4;
        background-color: $negative-1;
    }

    &.complete {
        color: $status-successful-2;
        background-color: $status-successful-1;
    }
}
