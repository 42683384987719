@import '/src/styles/common/variables';

.account-container {
    .header-action-row {
        .header-action-col,
        .content-action-col {
            display: flex;
        }
    }

    .payout-notification {
        padding: rem(12) rem(20);
        margin-bottom: rem(16);
        border-radius: rem(8);

        &.restricted,
        &.error {
            color: $negative-4;
            background-color: $negative-1;
        }

        &.complete {
            color: $status-successful-2;
            background-color: $status-successful-1;
        }
    }

    .account-total-container {
        height: rem(72);
        border: 1px solid $gray-10;
        border-bottom: 0;
        border-top-left-radius: rem(6);
        border-top-right-radius: rem(6);
        padding: rem(24) rem(20);

        .total-issues {
            .all-issues {
                @include text-style(500, rem(20), rem(30), $gray-900);
            }

            .total-number-issues {
                @include text-style(500, rem(16), rem(24), $ant-active-text);
                background-color: $ant-active-bg;
                border-radius: rem(16);
                padding: rem(2) rem(10) rem(2) rem(10);
            }
        }
    }

    .show-all-button,
    .create-issue-button,
    .remove-button {
        height: rem(40);
    }

    .show-all-button {
        @include text-style(500, rem(16), rem(24), $primary-text);
    }

    .create-issue-button,  .remove-button {
        @include text-style(500, rem(16), rem(24), #ffffff);
    }

    .account-table-container {
        .account-table {
            .ant-table-body {
                table {
                    table-layout: auto !important;
                }
            }

            .issue-cell {
                .issue-cell-subject {
                    @include text-style(500, rem(16), rem(24), $primary-text);
                }

                .issue-cell-message {
                    @include text-style(500, rem(14), rem(20), $gray-500);
                }
            }

            .status-cell {
                .status-cell-label {
                    padding: rem(2) rem(10) rem(2) rem(8);
                    border-radius: rem(16);

                    &.open {
                        color: $ant-active-text;
                        background-color: $ant-active-bg;
                    }

                    &.closed {
                        color: $success-700;
                        background-color: $success-50;
                    }
                }
            }

            .priority-cell {
                .priority-cell-label {
                    padding: rem(2) rem(10) rem(2) rem(10);
                    border-radius: rem(16);
                    .dot {
                        width: rem(6);
                        height: rem(6);
                        border-radius: 50%;
                    }

                    &.critical {
                        color: $error-700;
                        background-color: $error-50;
                        .dot {
                            background-color: $error-500;
                        }
                    }

                    &.high {
                        color: $warning-700;
                        background-color: $warning-50;
                        .dot {
                            background-color: $warning-500;
                        }
                    }

                    &.normal {
                        color: $ant-active-text;
                        background-color: $ant-active-bg;
                        .dot {
                            background-color: $primary-500;
                        }
                    }

                    &.low {
                        color: $primary-text;
                        background-color: $gray-100;
                        .dot {
                            background-color: $gray-500;
                        }
                    }
                }
            }
        }

        .ant-table,
        .ant-table-container,
        .ant-table-container table > thead > tr:first-child th:first-child,
        .ant-table-container table > thead > tr:first-child th:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
