.onboarding-step-one-layout {
    background: #fff url('~/public/images/bg.jpg') no-repeat center bottom fixed !important;
    background-size: cover !important;

    .onboarding-container {
        margin: auto;
        max-width: 460px;
        padding: 20px;
        background: #fff;
        border-radius: 10px;

        .ant-card {
            padding: 0;
            border: 0;
            box-shadow: none;
            background: transparent;
            .ant-card-head {
                padding: 0;
            }
            .ant-card-body {
                padding: 0;
                text-align: center;
            }
        }
        .ant-card-head-title {
            white-space: pre-wrap;
            text-align: center;
        }

        .title {
            color: #22313f;
            font-size: 40px;
            margin: 0px 0 5px;
        }

        .desc {
            color: #7a838c;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 30px;
        }

        .ant-btn {
            display: flex;
            justify-content: center;
        }
    }
}
