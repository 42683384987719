.revenue-page-wrapper {
    .ant-row {
        margin-left: 0;
        margin-right: 0;
    }
    .title-section {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 16px;

        &.title-goal-chart {
            margin-bottom: 0;
        }
    }

    .ant-input {
        min-height: 34px;
    }

    .ant-select-selector {
        min-height: 44px;
        .ant-select-selection-item {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .filter-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .filter-range-date {
            margin-bottom: 20px;

            .ant-tag {
                background-color: var(--secondary) !important;
                color: var(--primary) !important;
                border-color: var(--primary);
            }
        }

        .filter-by-time {
            margin-left: -16px;
            .select-time-filter {
                .ant-radio-button-wrapper {
                    border: none;
                    width: 93px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #667085;
                    text-align: center;

                    &.ant-radio-button-wrapper-checked {
                        background-color: var(--secondary) !important;
                        color: var(--primary) !important;
                        background: #eff8ff;
                        border-radius: 6px;
                        box-shadow: none;
                    }
                }

                .ant-radio-button-wrapper::before {
                    width: unset;
                    display: none;
                    height: 0px;
                }
            }
        }
    }
}

.select-type-goal {
    .ant-select-selector {
        align-items: center;
    }
}
