@import '@styles/common/variables';

.ant-dropdown-menu {
    .ant-dropdown-menu-item {
        .button-download {
            color: $primary-text;
            height: 36px;
        }

        .button-download:hover {
            color: $primary-text;
        }

        .ant-dropdown-menu-title-content {
            display: flex;
        }
    }
}
