@import '@styles/common/variables';
@import '@styles/common/mixins';

.formManualInput {
    :global {
        .ant-input-number .ant-input-number-input,
        .ant-input {
            height: 44px;
        }
    }
}
