@import '@styles/common/media';

.contact-payments-invoices {
    .search-item {
        width: 100%;
        margin: 0.5rem;
        width: 200px;
    }

    .ant-tabs {
        .ant-tabs-nav {
            height: 44px;
            @include phone {
                flex-wrap: wrap;
                height: auto;
            }
        }

        .ant-tabs-nav::before {
            border-bottom: 0;
        }
    }
}
