@import '@styles/common/variables';

.application-item-page-list {
    padding: 28px;
    background: #ffffff;
    border: 2px solid #eaecf0;
    border-radius: 15px;
    position: relative;
    height: 342px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .detail-application {
        .category {
            width: 79px;
            height: 25px;
            background: #fdf4ff;
            border-radius: 16px;
            display: flex;
            padding: 2px 10px;

            .icon {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #d444f1;
                display: flex;
                align-items: center;
                margin: auto 0;
            }

            .category-name {
                margin-left: 7px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.005em;
                color: #9f1ab1;
            }
        }

        .avatar {
            width: 56px;
            height: 56px;
            position: absolute;
            top: 28px;
            right: 28px;

            .avatar-image {
                width: 56px;
                height: 56px;
                border-radius: 50%;
            }
        }

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            margin-top: 4px;
            letter-spacing: -0.01em;
            color: #00030e;
            padding-right: 80px;
            min-height: 60px;
            @include ellipse-multi-lines(2);
        }

        .description {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: -0.01em;
            color: #667085;
            margin-top: 10px;
            min-height: 108px;

            @include ellipse-multi-lines(4);
        }

        .tags {
            gap: 8px;
            margin-top: 16px;
            min-height: 54px;
            @include ellipse-multi-lines();

            .tag-item {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.01em;
                color: #101828;
                margin-bottom: 0px;
            }
        }
    }
    hr {
        border: 2px solid #eaecf0;
        height: 0px;
    }

    .footer-item {
        display: flex;
        justify-content: space-between;

        .price-wrapper {
            display: flex;

            .value-price {
                margin-bottom: 0;
                margin-left: 9.5px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.005em;
                color: #12b76a;
            }

            .price {
                display: flex;
                align-items: center;
            }
        }
    }

    &.auto-container {
        height: auto;
    }
}
