@import '../../../styles/common/variables';
.tenant-setting-containers {
    &.company-profile-container {
        padding-left: 7px;
        box-shadow: none;
    }

    .tenant-setting-header-row {
        height: auto !important;
        padding-bottom: 8px;
    }

    .tenant-setting-form-row {
        .tenant-setting-form-item {
            &-input {
                min-height: rem(44);
            }
        }

        .tenant-setting-form-item-label {
            margin-bottom: 8px;
        }

        .ant-select-selector {
            min-height: 44px;
            display: flex;
            align-items: center;
        }

        .pin-hint {
            @include text-style(500, rem(12), rem(16), $help-gray);
        }
    }
    .ant-form {
        width: 100%;
        .ant-input {
            min-height: 2.5rem !important;
        }
    }
    .location-component {
        width: 100%;
        .ant-form {
            .grid-cols-2 {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .ant-form-item {
                    width: 50%;
                    &:nth-child(1) {
                        padding-right: 16.5px;
                    }
                    &:nth-child(2) {
                        padding-left: 16.5px;
                    }
                }
            }
            .ant-form-item-required {
                color: $help-gray;
            }
        }
    }
}
