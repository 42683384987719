@import '@styles/common/variables';

.otp-confirm-container {
    .ant-modal-header,
    .ant-modal-footer {
        border: none;
    }

    .ant-modal-title {
        padding-right: rem(16);
    }

    .ant-modal-body {
        padding-top: 0;
        padding-bottom: 0;
        .ant-form-item .ant-input {
            min-height: rem(44);
        }
    }

    .resend-otp-button {
        color: $primary;
        padding: 0;
        text-decoration: underline;
    }
}
