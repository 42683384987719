@import '@styles/common/_variables';

.filter-status-component {
    color: $gray-700;
    background: $white;
    line-height: 26px;
    border-radius: 12px;
    .ant-select-selector {
        padding: 6px 12px !important;
        height: 100% !important;
        .ant-select-selection-search-input {
            height: 100% !important;
        }
    }
}
