@import '../../../styles/common/variables';

.profile-settings-container {
    border-radius: rem(4);
    box-shadow: 0 rem(6) rem(18) rgba(0, 0, 0, 0.06);

    .profile-setting-col {
        &.avatar {
            border-right: rem(1) solid $neutral-divider;

            .profile-setting-avatar-row {
                .user-avatar-wrapper {
                    border-radius: 50%;
                    width: rem(106);
                    height: rem(106);
                    .user-avatar-image {
                        width: rem(106);
                        height: rem(106);
                    }

                    &-overlay {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        background-color: $black100;
                        top: 0;
                        left: 0;
                        background-color: rgba(0, 0, 0, 0.5);
                        display: none;
                    }

                    &:hover {
                        .user-avatar-wrapper-overlay {
                            display: flex;
                        }
                    }
                }

                .user-role-wrapper {
                    .user-role {
                        padding: rem(4) rem(8);
                        min-height: rem(24);
                        height: auto;
                        background-color: $dark-blue;
                        border-radius: rem(4);
                        @include text-style(500, rem(12), rem(16), $white);
                    }
                }

                .update-profile-icon {
                    border-radius: 50%;
                    height: rem(24);
                    width: rem(24);
                    right: rem(8);
                    bottom: rem(8);
                    box-shadow: 0 rem(4) rem(4) rgba(0, 0, 0, 0.08);
                    background-color: $white;
                    z-index: 10;
                }
            }
        }

        &.information {
            .profile-setting-information-row {
                .profile-setting-information-col {
                    border-right: rem(1) solid $neutral-divider;

                    .setting-information-section {
                        @include text-style(500, rem(16), rem(24), $neutral-black);
                    }

                    .user-info-item {
                        display: flex;
                        flex-direction: column;

                        &-label {
                            @include text-style(500, rem(12), rem(16), $help-gray);
                        }

                        &-value {
                            margin-top: rem(5);
                            margin-bottom: rem(24);
                        }
                    }
                }
            }

            .form-container {
                .ant-form-item-label {
                    padding: 0 0 0;
                    label {
                        @include text-style(500, rem(12), rem(16), $help-gray);
                    }
                }

                .ant-form-item {
                    margin-bottom: rem(16);
                    .ant-input {
                        padding: 0 11px;
                        box-shadow: none;
                        border-color: $line;
                        height: 32px;

                        &:hover,
                        &:active,
                        &:focus {
                            border-color: $line;
                        }
                    }
                }

                .confirm-new-password-form-item {
                    margin-top: rem(36);
                }

                .change-password-button {
                    @include text-style(500, rem(16), rem(24), $gray-icon);
                    height: rem(42);
                    border-color: $line;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $accent-default;
                        border-color: $accent-default;
                    }
                }
            }
        }
    }
}
