@import '@styles/common/variables';

.pin-confirm-container {
    .ant-modal-header,
    .ant-modal-footer {
        border: none;
    }

    .ant-modal-title {
        padding-right: rem(16);
    }

    .ant-modal-body {
        padding-top: 0;
        padding-bottom: 0;
        .ant-form-item .ant-input {
            min-height: rem(34);
        }
    }

    .ant-select-selector {
        display: flex;
        align-items: center;
        height: 44px !important;

        .ant-select-selection-search {
            display: flex;
            align-items: center;
        }
    }
}
