@import '@styles/common/variables';

.subscription-page-wrapper {
    .search-status,
    .select-partner,
    .search-item {
        margin-right: 30px;
        margin-bottom: 10px;

        @include max-size(577px) {
            width: 100%;
        }
        width: 200px;
    }
    .list-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        @include max-size(577px) {
            flex-direction: column;
            align-items: start;
            gap: 8px;
        }
    }
}

.icon-action-subscription-list {
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
    }
}

.reseller-modal {
    .ant-select {
        width: 100%;
    }
}
