@import '@styles/common/variables';

.payouts-container {
    .payout-notification {
        padding: rem(12) rem(20);
        margin-bottom: rem(16);
        border-radius: rem(8);

        &.restricted,
        &.error {
            color: $negative-4;
            background-color: $negative-1;
        }
    }
}

.payouts-table-container {
    .pagination-container {
        border: 1px solid #eaecf0;
        border-top: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.btn-withdraw {
    a {
        @include max-size(577px) {
            width: 100%;
        }
        button {
            display: flex;
            width: 100%;
            justify-content: center;
        }
    }
}
