@import '/src/styles/common/variables';
.create-helpdesk-container {
    height: calc(100vh - rem(124));

    .create-helpdesk-col {
        border: rem(1) solid $gray-10;
        .create-helpdesk-title {
            color: $gray-900;
            font-weight: 500;
            font-size: 20px;
        }
    }

    .create-helpdesk-form {
        height: calc(100% - rem(80));
        .create-helpdesk-form-col {
            height: auto;
            &.col-left {
                padding: rem(24) rem(72);
            }

            &.col-right {
                border-left: rem(1) solid $gray-10;
                padding: rem(24);
            }

            .helpdesk-form-item {
                .ant-form-item-label {
                    label {
                        @include text-style(500, rem(16), rem(24), $gray-700);
                    }
                }

                &.form-item-subject .ant-input {
                    height: rem(44);
                }

                &.form-item-message .ant-input {
                    resize: none;
                    min-height: rem(148);
                }
            }
            .ant-select-selector {
                display: flex;
                align-items: center;
                height: 44px;
                .ant-select-selection-search {
                    input {
                        height: 100%;
                    }
                }
            }
            .ant-select {
                width: 100%;
            }
        }
    }
}

.create-helpdesk-custom {
    @include max-size(577px) {
        border: 1px solid $neutral-10;
    }
}
