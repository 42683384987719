@import '/src/styles/common/variables';

.helpdesk-container {
    .header-action-row {
        .header-action-col {
            display: flex;

            .search-helpdesk-status {
                width: 200px;
            }
        }
    }

    .helpdesk-total-container {
        height: rem(72);
        border: 1px solid $gray-10;
        border-bottom: 0;
        border-top-left-radius: rem(6);
        border-top-right-radius: rem(6);
        padding: rem(24) rem(20);

        .total-issues {
            .all-issues {
                @include text-style(500, rem(20), rem(30), $gray-900);
            }

            .total-number-issues {
                @include text-style(500, rem(16), rem(24), $ant-active-text);
                background-color: $ant-active-bg;
                border-radius: rem(16);
                padding: rem(2) rem(10) rem(2) rem(10);
            }
        }
    }

    .show-all-button,
    .create-issue-button {
        height: rem(40);
    }

    .show-all-button {
        @include text-style(500, rem(16), rem(24), $primary-text);

        &.show-all-active {
            background-color: $primary;
            color: $white;
            border: none;
        }
    }

    .show-all-button:focus {
        border-color: #d9d9d9;
    }

    .create-issue-button {
        @include text-style(500, rem(16), rem(24), #ffffff);
    }

    .helpdesk-table-container {
        .helpdesk-table {
            .ant-table-body {
                table {
                    table-layout: auto !important;
                }
            }

            .issue-cell {
                .issue-cell-subject {
                    @include text-style(500, rem(14), rem(24), $primary-text);
                }

                .issue-cell-message {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    @include text-style(500, rem(12), rem(20), $gray-500);
                }
            }

            .status-cell {
                .status-cell-label {
                    padding: rem(2) rem(10) rem(2) rem(8);
                    border-radius: rem(16);

                    &.open {
                        color: $ant-active-text;
                        background-color: $ant-active-bg;
                        font-size: 14px;
                    }

                    &.closed {
                        color: $success-700;
                        background-color: $success-50;
                        font-size: 14px;

                    }
                }
            }

            .priority-cell {
                .priority-cell-label {
                    padding: rem(2) rem(10) rem(2) rem(10);
                    border-radius: rem(16);

                    .dot {
                        width: rem(6);
                        height: rem(6);
                        border-radius: 50%;
                    }

                    &.critical {
                        color: $error-700;
                        background-color: $error-50;
                        font-size: 14px;

                        .dot {
                            background-color: $error-500;
                        }
                    }

                    &.high {
                        color: $warning-700;
                        background-color: $warning-50;
                        font-size: 14px;

                        .dot {
                            background-color: $warning-500;
                        }
                    }

                    &.normal {
                        color: $ant-active-text;
                        background-color: $ant-active-bg;
                        font-size: 14px;

                        .dot {
                            background-color: $primary-500;
                        }
                    }

                    &.low {
                        color: $primary-text;
                        background-color: $gray-100;
                        font-size: 14px;

                        .dot {
                            background-color: $gray-500;
                        }
                    }
                }
            }

            .customer-cell {
                .ant-avatar {
                    background-color: $primary;
                    min-width: 40px;
                    display: flex;
                }
            }

            .ant-image {
                width: 40px;
                height: 40px;

                img {
                    border-radius: 50%;
                    object-fit: contain;
                    width: auto;
                    height: 100%;
                }
            }

            .partner-cell {
                .ant-avatar {
                    background-color: $primary;
                }
            }
        }

        .ant-table,
        .ant-table-container,
        .ant-table-container table>thead>tr:first-child th:first-child,
        .ant-table-container table>thead>tr:first-child th:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .pagination-container{
        border: 1px solid #eaecf0;
        border-top: none;
    }
}