@import '@styles/common/_variables';

.resource-file-container {
    padding-bottom: 50px;

    .flex {
        @include max-size(577px) {
            justify-content: space-between;
            gap: 8px;
        }
    }

    .btn-create-folder {
        background-color: var(--secondary);
        color: var(--primary);
        border: none;
        &:hover {
            color: var(--primary);
            color: #fff;
        }
    }

    .ant-collapse {
        border: 0px;
        margin-top: 20px;
    }

    .ant-collapse-item {
        border: 0px;
    }

    .ant-collapse-header {
        background: #fff;
        padding: 12px 0 !important;

        .ant-collapse-header-text {
            font-weight: 700;
            font-size: 18px;
            color: $primary-text;
        }
    }

    .ant-collapse-content {
        background: #fff;
        border: 0;

        .ant-collapse-content-box {
            padding: 0;
        }

        .file-item {
            background: $background-input;
            padding: 22px;
            border-radius: 8px;
            margin-bottom: 20px;
        }

        .folder-item {
            background: #fff;
            padding: 15px 20px;
            border-radius: 8px;
            border: 1px solid #d3d6d9;
            border-radius: 8px;

            :hover {
                color: $primary;
            }
        }

        .download {
            color: $primary;
        }
    }

    .select-file-for-folder {
        .ant-form-item-control {
            text-align: center;
            border: 1px dashed #d3d6d9;
            padding: 30px;

            .ant-form-item-explain-connected {
                position: absolute;
                bottom: -25px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
        }
    }

    .select-folder {
        .item-folder {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;

            .ant-radio-button-wrapper-checked {
                background-color: var(--secondary);
                border-color: var(--secondary);
                color: var(--primary);
                &:focus-within {
                    box-shadow: none;
                }
            }

            .ant-radio-button-wrapper {
                border-radius: 8px !important;
                border-left-width: 1px;
                width: 150px;
                @include ellipse-multi-lines(1);
            }

            .ant-radio-button-wrapper:hover {
                color: var(--primary);
            }

            .ant-radio-button-wrapper::before {
                display: none;
            }
        }
    }

    .create-folder {
        .ant-input {
            height: 44px;
        }
    }

    .upload-document-form {
        .select-type {
            .item-type {
                .ant-radio-wrapper {
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }

        .ant-input {
            height: 44px;
        }

        .field-url {
            svg {
                margin-right: 6px;

                path {
                    fill: var(--primary);
                }
            }
        }

        .ant-input-affix-wrapper {
            padding-top: 0px;
            padding-bottom: 0px;

            .ant-input {
                height: 42px;

                img {
                    width: 20px;
                    height: 10px;
                }
            }
        }
    }
}
