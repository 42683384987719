.edit-payment-container {
    width: 100%; /* Allow full width to be responsive */
    max-width: 520px;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;

    @media (max-width: 768px) {
        width: 90%;
        padding: 15px;
    }

    @media (max-width: 480px) {
        width: 100%;
        padding: 10px;
        gap: 10px;
    }
}

.form-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 480px) {
        gap: 10px;
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
        font-size: 0.9rem;
        font-weight: 500;
        color: #555;

        @media (max-width: 480px) {
            font-size: 0.85rem; // Reduce label font size on smaller screens
        }
    }

    input,
    select {
        padding: 12px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #e9e6e6;
        color: #333;
        width: 100%; /* Ensure full width */
        box-sizing: border-box; /* Ensure padding is accounted for */

        @media (max-width: 480px) {
            font-size: 0.95rem; // Slightly smaller input text for mobile
        }
    }

    input:read-only {
        background-color: #f6f5f5;
        border: none;
    }
}

.form-row {
    display: flex;
    gap: 10px;

    input {
        width: 100%; /* Ensure each input field takes full width */
    }

    .form-group {
        flex: 1; /* Allow both input fields to take up equal space */
    }

    @media (max-width: 768px) {
        flex-direction: column; /* Stack rows on mobile */
        gap: 10px;
    }
}

.card-number-container {
    display: flex;
    align-items: center;
    position: relative;

    input {
        width: 100%; /* Ensure input takes full width */
        padding-right: 50px; /* Add padding to the right for the icon */
    }

    .card-logo {
        position: absolute;
        right: 10px; /* Align the icon inside the input, to the right */
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: auto;
    }
}
