.tableWrapper {
    height: 600px; /* Adjust this height as needed */
    overflow-y: auto;

    :global {
        .ant-table-wrapper .ant-table-thead>tr>th {
            text-align: center;
        }
    }
}
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6); // semi-transparent background
    backdrop-filter: blur(5px); // adds the blur effect
    z-index: 1000; // ensure it's on top of other content
}

.customTooltip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;

    .copyCode {
        margin-left: 16px;
        font-size: 12px;
        color: #f0f0f0;
        white-space: nowrap;
        background-color: inherit;
        cursor: pointer;
        font-weight: normal;
    }
}

.textDate {
    font-size: 12px;
}

.headerColCustom {
    display: flex;
    flex-direction: column;
}

.bodyColCustom {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


