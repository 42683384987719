@import './variables';
@import './mixins';

//
// Titles
//
$title-42-font-size: rem(42);
$title-32-font-size: rem(32);
$title-24-font-size: rem(24);
$title-16-font-size: rem(16);
$title-14-font-size: rem(14);

$title-42-line-height: rem(58);
$title-32-line-height: rem(40);
$title-24-line-height: rem(36);
$title-16-line-height: rem(20);
$title-18-line-height: rem(18);

.title-42-bold {
    font-size: $title-42-font-size;
    line-height: $title-42-line-height;
    font-weight: 700;
}

.title-32-bold {
    font-size: $title-32-font-size;
    line-height: $title-32-line-height;
    font-weight: 700;
}

.title-24-bold {
    font-size: $title-24-font-size;
    line-height: $title-24-line-height;
    font-weight: 700;
}

.title-16-bold {
    font-size: $title-16-font-size;
    line-height: $title-16-line-height;
    font-weight: 700;
}

.title-24-medium {
    font-size: $title-24-font-size;
    line-height: $title-24-line-height;
    font-weight: 500;
}

.title-16-medium {
    font-size: $title-16-font-size;
    line-height: $title-16-line-height;
    font-weight: 500;
}

.title-14-bold {
    font-size: $title-14-font-size;
    line-height: $title-18-line-height;
    font-weight: 700;
}

//
// Subtitles
//
$subtitle-20-font-size: rem(20);
$subtitle-18-font-size: rem(18);
$subtitle-20-line-height: rem(20);
$subtitle-18-line-height: rem(28);

.subtitle-20-bold {
    font-weight: 700;
    font-size: $subtitle-20-font-size;
    line-height: $subtitle-20-line-height;
}

.subtitle-20-medium {
    font-weight: 500;
    font-size: $subtitle-20-font-size;
    line-height: $subtitle-20-line-height;
}

.subtitle-18-bold {
    font-weight: 700;
    font-size: $subtitle-18-font-size;
    line-height: $subtitle-18-line-height;
}

.subtitle-18-semi-bold {
    font-weight: 700;
    font-size: $subtitle-18-font-size;
    line-height: $subtitle-18-line-height;
}

.subtitle-18-medium {
    font-weight: 500;
    font-size: $subtitle-18-font-size;
    line-height: $subtitle-18-line-height;
}

.subtitle-18-regular {
    font-weight: 400;
    font-size: $subtitle-18-font-size;
    line-height: $subtitle-18-line-height;
}

//
// Body
//
$body-16-font-size: rem(16);
$body-14-font-size: rem(14);

$body-16-line-height: rem(24);
$body-14-line-height: rem(22);

.body-16-semi-bold {
    font-weight: bold;
    font-size: $body-16-font-size;
    line-height: $body-16-line-height;
}

.body-16-semi-bold-underline {
    font-weight: bold;
    font-size: $body-16-font-size;
    line-height: $body-16-line-height;
    text-decoration-line: underline;
}

.body-16-regular {
    font-size: $body-16-font-size;
    line-height: $body-16-line-height;
}

.body-16-regular-underline {
    font-size: $body-16-font-size;
    line-height: $body-16-line-height;
    text-decoration-line: underline;
}

.body-14-semi-bold {
    font-weight: bold;
    font-size: $body-14-font-size;
    line-height: $body-14-line-height;
}

.body-14-semi-bold-underline {
    font-weight: bold;
    font-size: $body-14-font-size;
    line-height: $body-14-line-height;
    text-decoration-line: underline;
}

.body-14-regular {
    font-size: $body-14-font-size;
    line-height: $body-14-line-height;
}

.body-14-regular-underline {
    font-size: $body-14-font-size;
    line-height: $body-14-line-height;
    text-decoration-line: underline;
}

//
// Captions
//
$caption-12-font-size: rem(12);

$caption-12-line-height: rem(18);

.caption-12-semi-bold {
    font-weight: 700;
    font-size: $caption-12-font-size;
    line-height: $caption-12-line-height;
}

.caption-12-semi-bold-underline {
    font-weight: 700;
    font-size: $caption-12-font-size;
    line-height: $caption-12-line-height;
    text-decoration-line: underline;
}

.caption-12-regular {
    font-size: $caption-12-font-size;
    line-height: $caption-12-line-height;
}

.caption-12-regular-underline {
    font-size: $caption-12-font-size;
    line-height: $caption-12-line-height;
    text-decoration-line: underline;
}

// color status

.status-completed {
    background-color: $status-successful-2;
    color: $neutral-1;
}

.status-failed {
    background-color: $status-error-2;
    color: $neutral-1;
}

.status-pending {
    background-color: $status-warning-2;
    color: $neutral-1;
}

.status-rejected {
    background-color: $neutral-4;
    color: $neutral-black-2;
}
