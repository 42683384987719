$tablet-width: 768px;
$small-desktop-width: 992px;
$medium-desktop-width: 1200px;
@mixin phone {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$small-desktop-width - 1px}) {
        @content;
    }
}

@mixin tablet-and-phone {
    @media (max-width: #{$small-desktop-width - 1px}) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: #{$small-desktop-width}) and (max-width: #{$medium-desktop-width - 1px}) {
        @content;
    }
}

@mixin medium-desktop {
    @media (min-width: #{$medium-desktop-width - 1px}) and (max-width: #{1300px}) {
        @content;
    }
}

@mixin above-tablet {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}

@mixin medium-phone {
    @media (min-width: 319px) and (max-width: 376px) {
        @content;
    }
}

@mixin small-phone {
    @media (max-width: 320px) {
        @content;
    }
}

@mixin under-medium-desktop {
    @media (max-width: #{$medium-desktop-width}) {
        @content;
    }
}
