// FONTS
$common-font: 'Open Sans', sans-serif;
$secondary-font: 'Poppins', sans-serif;
$font-inter: 'SVN-Gilroy', 'inter';

$primary: var(--primary);
$secondary: var(--secondary);
$secondary_1: var(--secondary_1);
$secondary_2: var(--secondary_2);

$white: #ffffff;
$background-footer: #fafbfc;
$primary-text: #344054;
$primary-bg-color: #22313f;
$warning: #ff9446;
$danger: #fe5d5d;

$text-successful: #13ce66;
$background-btn: #316bea;
$background-input: #f4f5f5;
$neutral-500: #22313f;
$neutral-100: #202031;
$neutral-80: #4e5a65;
$neutral-60: #7a838c;
$neutral-50: #a6a6ad;
$neutral-40: #a7adb2;
$neutral-10: #f4f5f5;
$neutral-10-bg: #f4f4f5;
$neutral-100-bg: #4377ff;

// Verify account
$verified-bg: #d0fbe3;

// BASE COLORS
$primary-10: #f4f4ff;
$primary-6: #ffa827;
$primary-5: #f2ad00;
$primary-4: #f2bb24;
$primary-3: #ffd55f;
$primary-2: #fde7aa;
$primary-1: #fef6df;
$primary-light: #ffba0a;
$primary-dark: #f5b30a;
$negative-4: #ff4949;
$negative-3: #ff6a64;
$negative-2: #ffb6b6;
$negative-1: #ffe5dd;
$info-5: #0091d7;
$info-4: #1fb6ff;
$info-3: #85d7ff;
$info-2: #a5e2ff;
$info-1: #edfbff;
$neutral-5: #d3dce6;
$neutral-4: #e5e9f2;
$neutral-3: #eff2f7;
$neutral-2: #f9fafc;
$neutral-1: #fff;
$neutral-black-5: #050504;
$neutral-black-4: #1f2d3d;
$neutral-black-3: #3c4858;
$neutral-black-2: #8492a6;
$neutral-black-1: #c2cedb;
$neutral-black: #192a3e;
$neutral-gray: #90a0b7;
$neutral-divider: #ebeff2;

// Status
$status-successful-2: #54b94f;
$status-successful-1: #eef8ed;
$status-information-2: #2f6bff;
$status-information-1: #ebf0fe;
$status-warning-2: #f0893c;
$status-warning-1: #fdf3eb;
$status-error-2: #d3303a;
$status-error-1: #fbeaeb;
$status-error-3: #ff4d4f;

// CUSTOM COLORS
$gray-text: rgba(
    $color: #333,
    $alpha: 0.6,
);
$neutral-10-bg: #f4f4f5;
$branding-yellow-bg: linear-gradient(60.29deg, #f9a51a -4.93%, #fbb612 18.27%, #ffdd00 71.59%);

$outline-gray: #d6d6d6;

$neutral-body: #333333;
$neutral-black-100: #2a2846;
$neutral-bg: #f2f3f4;
$neutral-mid-grey: #b3b7bc;
$light-gray: #f3f5fb;
$black100: #151e40;
$gray: #f1f4fd;

$primary-bg-btn: #fb7331;
$accent-border: #715def;
$accent-default: #109cf1;

// TYPE SIZES
$font_size: 16px;
$font_size_rem: 16px;

// SCREEN SIZES
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-ml: 1024px;
$screen-xl: 1200px;
$screen-xxl: 1440px;
$screen-xxxl: 1600px;

$ant-active-text: #175cd3;
$ant-active-bg: #eff8ff;
$ant-border-radius-base: 6px;

$primary-500: #2e90fa;

$gray-500: #667085;
$gray-10: #f0f0f0;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-700: #344054;
$gray-900: #101828;
$success-700: #027a48;
$success-50: #ecfdf3;

$warning-700: #b54708;
$warning-500: #f79009;
$warning-50: #fffaeb;

$error-700: #b42318;
$error-500: #f04438;
$error-50: #fef3f2;

$dark-blue: #334d6e;
$help-gray: #818e9b;
$line: #d3d8dd;
$gray-icon: #c2cfe0;

@import './mixins';

$container-position: 124px;
