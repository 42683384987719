label.product-header-section {
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
    .anticon {
        margin-right: 20px;
        svg {
            font-size: 20px;
        }
    }
}
