@import '@styles/common/variables';
.subscription {
    .ant-btn-text:not(:disabled):hover {
        background-color: $white;
    }
    .contact-button,
    .contact-button:hover {
        color: $primary;
    }
    margin: 2rem 0;
    .ant-table-container {
        border: none;
    }
    .ant-table-wrapper {
        border-radius: 8px !important;
    }
}

.contact-insight {
    border-radius: 8px;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    min-width: 100%;
    table-layout: auto;
    border: 0.5px solid $gray-200;
    background-color: $white;
    &::before {
        box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
    }
}

.contact-total-insight {
    padding: 20px;
}

.last-expiration-subscription {
    color: $neutral-60;
    display: flex;
    flex-direction: column;
}
