@import '../../../styles//common/variables';
.brand-item {
    .ant-form-item {
        margin-bottom: 0;
    }

    .brand-item-image {
        .ant-upload.ant-upload-select-picture-card {
            margin: 0;
            border: unset;
            background-color: $white;

            .upload {
                display: flex;
                justify-items: flex-start;
            }
        }

        .logo {
            .ant-upload {
                justify-content: left;
            }

            .ant-upload.ant-upload-select  {
                width: rem(215) !important;
                height: rem(42) !important;
            }

            .img {
                height: inherit;
            }
        }

        .favicon {
            .ant-upload.ant-upload-select  {
                width: rem(42) !important;
                height: rem(42) !important;
            }

            .img {
                height: inherit;
            }
        }

        .btn-upload {
            span {
                margin-left: 0;
            }
        }
    }

    &-mask {
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: none;
    }
    &-image {
        &:hover {
            .brand-item-mask {
                display: flex;
            }
        }
    }
}
