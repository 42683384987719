@import '@styles/common/variables';
.container {
    max-width: 1600px;
    margin: auto;
    padding: 16px;

    @media (max-width: 768px) {
        padding: 8px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.textLarge {
    font-size: 24px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.textSmall {
    font-size: 13px;
    color: $neutral-80;
    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.textMedium {
    font-size: 18px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 18px;
    }
}

.rowContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.tableContainer {
    margin-top: 20px;
    overflow-x: auto;

    @media (max-width: 768px) {
        margin-top: 10px;
    }
}

.descriptionsContainer {
    margin-bottom: 20px;
    margin-top: 20px;

    @media (max-width: 768px) {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.textDate {
    font-size: 12px;
}

.tableWrapper {
    height: 500px; /* Adjust this height as needed */
    overflow-y: auto;
}

.countSettlement {
    display: inline-block;
    padding: 2px 6px;
    background-color: #e5e5e5;
    border-radius: 4px;
    font-size: 12px;
    margin-left: 4px;
    line-height: normal;
}

.customOverlay {
    :global {
        .ant-tooltip-inner {
            background-color: $neutral-100;
            color: #f0f0f0;
            border-radius: 4px;
            padding: 8px 12px;
            font-size: 12px;
            line-height: 1.5;
            max-width: 350px;
            word-wrap: break-word;
            display: flex;
            align-items: center;
        }

        .ant-tooltip-arrow {
            border-color: #1a1a1a;
        }
    }
}

.customTooltip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #f0f0f0;
    font-weight: 700;

    .copyCode {
        margin-left: 16px;
        font-size: 12px;
        color: #f0f0f0;
        white-space: nowrap;
        background-color: inherit;
        cursor: pointer;
        font-weight: normal;
    }
}

.headerSettlementTitle {
    color: $neutral-80;
    font-size: 12px;
}
.stateContainer {
    display: flex;
    align-items: center;
}

.stateLabel {
    writing-mode: vertical-rl;
    text-transform: uppercase;
    color: #6c757d; // Adjust color as needed for the gray tone
    font-size: 12px;
    letter-spacing: 0.1em;
    margin-right: 8px; // Adjust spacing as needed
}

.stateValue {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #212529; // Adjust color as needed for the text
}

.stateIcon {
    width: 16px;
    height: 16px;

    border-radius: 50%;
    margin-right: 8px;

    &.stateApproved {
        background-color: green;
    }

    &.stateAccruing {
        background-color: gray;
    }
}

/* Tabs Custom Styles */
.ant-tabs-nav {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &::before {
        border: none; /* Remove the default bottom border */
    }
}

.customTabs {
    :global {
        .ant-tabs-tab {
            margin-right: 24px; /* Adjust the spacing between tabs */
            font-size: 16px;
            font-weight: bold;
            color: #6c757d;
            text-transform: capitalize;
            transition: color 0.3s;

            &:hover {
                color: #000;
            }
        }

        .ant-tabs-tab-active {
            color: #000;
        }

        .ant-tabs-ink-bar {
            height: 2px;
            background-color: #000 !important; /* Set the color of the active tab underline */
        }

        .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #000 !important;
            font-weight: 500;
        }

        .ant-tabs-tab-btn {
            transition: color 0.3s;
            font-size: 13px;
            color: #6c757d;
        }
    }
}

.tableSettlement {
    // font 13 for all
    :global {
        .ant-table-thead > tr > th,
        .ant-table .ant-table-tbody > tr > td,
        .ant-tag {
            font-size: 13px;
        }
    }
}

// scrollbar styles for table in every browser like safari, chrome, firefox

.tableSettlement {
    :global {
        .ant-table-body {
            overflow: auto;
            max-height: 500px; /* Adjust this height as needed */
            scrollbar-width: thin;
            scrollbar-color: #d3d3d3 #f0f0f0;
        }

        .ant-table-body::-webkit-scrollbar {
            width: 8px;
        }

        .ant-table-body::-webkit-scrollbar-thumb {
            background-color: #d3d3d3;
            border-radius: 4px;
        }

        .ant-table-body::-webkit-scrollbar-track {
            background-color: #f0f0f0;
        }

        .ant-table-body::-webkit-scrollbar-thumb:hover {
            background-color: #b3b3b3;
        }
    }
}

.textTooltip {
    max-width: 120px;
}
