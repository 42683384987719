.product-item {
    overflow: hidden;
    border: 2px solid #eaecf0;
    border-radius: 15px;
    margin: 0 10px 20px;
    overflow: hidden;
    .ant-card-body {
        padding: 0px;
        .ant-image {
            width: 100%;
        }
        .service-name {
            font-size: 16px;
            color: #000;
            padding: 10px 20px 0;
            font-weight: 700;
            margin-bottom: 0px;
        }
        .ant-divider {
            border: 1px solid #eaecf0;
            margin: 20px;
            width: calc(100% - 40px);
            min-width: auto;
        }
        .package-content {
            margin: 15px 20px;
            color: #667085;
            font-size: 16px;
        }
        .frequency {
            margin: 0px 20px;
            font-size: 16px;
        }
        .actions {
            display: flex;
            padding: 0px 20px 20px;

            .chip {
                display: inline-block;
                .content {
                    display: flex;
                    align-items: center;
                    border-radius: 16px;
                    padding: 2px 10px 2px 8px;
                    font-size: 14px;
                    display: flex;
                    height: 22px;
                    background-color: #00c48c;
                    .dot {
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        margin: 0 7px 0 0;
                        background-color: #ffffff;
                    }
                    .label {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: center;
                        color: #ffffff;
                    }
                    &.inactive {
                        background-color: #f4f5f5;
                        .dot {
                            background-color: #a7adb2;
                        }
                        .label {
                            color: #a7adb2;
                        }
                    }
                }
            }

            .money {
                display: flex;
                align-items: center;
                color: #12b76a;
                font-weight: 500;
                font-size: 16px;
                .anticon {
                    margin-right: 10px;
                    svg {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.service-dropdown-container {
    .ant-dropdown-menu-item {
        height: 44px;

        .ant-btn {
            padding: 0;
        }

        .anticon svg {
            font-size: 18px;
        }

        .ant-dropdown-menu-title-content {
            font-size: 12px;
        }

        .ant-btn {
            border: 0;
            background: transparent;
        }
    }
}

.service-drawer {
    .ant-card-body {
        padding: 0;
        .ant-form-item-label > label {
            font-weight: 500;
        }

        .ant-input {
            height: 44px;
            border: 1px solid #d3d6d9;
            line-height: 2.3;
        }

        .ant-input-number-input {
            height: 44px;
        }

        .ant-upload.ant-upload-select-picture-card {
            width: 100%;
            height: 200px;
            img {
                object-fit: cover;
            }
            .upload {
                margin-top: 10px;
            }
        }
    }
}
