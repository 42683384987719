@import '@styles/common/variables';
@import '@styles/common/media';

.application-list-container {
    .list-header {
        margin-bottom: 28px;
    }

    .item-application {
        cursor: pointer;
    }
}

.application-detail-container {
    .detail-application-wrapper {
        padding-bottom: 32px;
        .back-to-list {
            display: flex;
            align-items: baseline;
            margin-left: 9px;

            .back-to-list {
                cursor: pointer;
            }

            .text-back {
                margin-bottom: 0px;
                margin-left: 12px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #667085;
                cursor: pointer;
            }
        }
        .section-item + .section-item {
            margin-top: 32px;
        }
        .main-content-wrapper {
            padding: 27px 88px 0 88px;

            @include tablet-and-phone {
                padding: 27px 0px 0px 0px;
            }

            .detail-application {
                padding: 33px 41px;
                border: 1px solid #eaecf0;
                border-radius: 8px;

                .avatar-wrapper {
                    display: flex;
                    align-items: center;

                    .avatar {
                        width: 161px;
                        height: 161px;
                        border-radius: 50%;
                        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
                            0px 4px 6px -2px rgba(16, 24, 40, 0.03);

                        .avatar-image {
                            width: 161px;
                            height: 161px;
                            border-radius: 50%;
                        }
                    }
                }

                .application-information {
                    .header-card {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;

                        .title {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 24px;
                            color: #101828;
                            @include ellipse-multi-lines();
                        }

                        .action {
                            display: flex;
                            justify-content: center;
                        }
                    }

                    .category {
                        width: 71px;
                        height: 32px;
                        background: #eef4ff;
                        border-radius: 16px;
                        padding: 4px 12px;

                        .name-category {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 150%;
                            text-align: center;
                            letter-spacing: -0.005em;
                            margin-bottom: 0px;
                            color: #3538cd;
                        }
                    }

                    .description {
                        margin-top: 24px;

                        .title-description {
                            margin-bottom: 4px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 150%;
                            letter-spacing: -0.005em;
                            color: #344054;
                        }

                        .content-description {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;
                            letter-spacing: -0.005em;
                            color: #667085;
                            margin-bottom: 0px;
                            @include ellipse-multi-lines(6);
                        }
                    }
                }
            }

            .plan-service-wrapper {
                .plan-service-header {
                    margin-top: 32px;

                    .plan-title {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: #101828;
                        margin-bottom: 0px;
                    }
                }

                .list-plan {
                    margin-top: 28px;

                    .item-plan {
                        border: 1.5px solid #eaecf0;
                        height: 292px;
                        border-radius: 5px;

                        &.title {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        &.card-item {
                            padding: 20px;

                            .icon-plan {
                                margin-bottom: 20px;

                                .action-icon {
                                    width: 40px;
                                    height: 40px;
                                    margin: 0 auto;
                                }
                            }

                            .name-plan {
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 30px;
                                text-align: center;
                                color: #175cd3;
                                margin-bottom: 8px;
                                min-height: 60px;
                                @include ellipse-multi-lines();
                            }

                            .price {
                                font-weight: 600;
                                font-size: 24px;
                                line-height: 32px;
                                text-align: center;
                                color: #101828;
                                margin-bottom: 8px;
                                @include ellipse-multi-lines(1);
                            }

                            .action {
                                button {
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    margin-top: 36px;

                                    &.active-subscription {
                                        border: 1px solid #00c48c;
                                        background: #d5f2ea;
                                        color: #00c48c;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .payment-method {
                .card-header {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.active-subscription-plan {
    color: $neutral-500;
    background-color: $neutral-10;
    .heading {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #101828;
        margin-bottom: 15px;
    }
    .card-header {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
    }
    .active-plan-heading {
        font-weight: 400;
        font-size: 14px;
        color: $neutral-40;
    }
    .active-plan-content {
        font-weight: 500;
        font-size: 16px;
    }
}

.user-card-item {
    display: flex;
    background: #f4f5f5;
    border: 1px solid #0084f4;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    .default-ribbon {
        position: absolute;
        left: -1px;
        top: 0;
        background: #0084f4;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 8px;
        color: white;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 6px;
        height: 16px;
        line-height: 16px;
    }

    .card-img {
        display: flex;
        padding-right: 20px;
        align-items: center;
        img {
            height: 16px;
        }
    }
}

.application-edit-container {
    .edit-application-wrapper {
        .header-section {
            display: flex;
            justify-content: space-between;

            .title {
                display: flex;
                width: 20px;
                height: 20px;

                .icon-overview {
                    width: 20px;
                    height: 20px;
                }

                .text-title {
                    margin-left: 12px;
                }
            }

            .action-section {
                display: flex;

                .btn-cancel {
                    margin-right: 16px;
                }
            }
        }

        .main-content-wrapper {
            padding-top: 27px;

            .value-price {
                display: flex;
            }
        }
    }
}

.payment-modal {
    .payment-item {
        background: #f4f5f5;
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        &.active {
            border: 1px solid $primary;
        }

        .card-no {
            font-weight: 700;
            font-size: 16px;
            color: $neutral-500;
        }

        .card-img {
            img {
                height: 24px;
            }
        }
    }

    .new-card {
        font-weight: 500;
        font-size: 16px;
        color: $neutral-500;
        margin-top: 5px;
    }

    .ant-radio-group {
        width: 100%;
    }

    .ant-radio-wrapper {
        display: flex;
        align-items: center;
    }

    .ant-radio-inner {
        width: 24px;
        height: 24px;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: var(--primary);
    }

    .ant-radio-inner::after {
        background-color: var(--primary);
        width: 28px;
        height: 28px;
        margin-top: -14px;
        margin-left: -14px;
    }

    .ant-radio-checked::after {
        border-color: var(--primary);
    }
}
@media (max-width: 1200px - 1px) {
    .avatar-wrapper {
        justify-content: center;
    }

    .header-card {
        padding-top: 12px;
    }
}
