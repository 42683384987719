.pricing-form-item {
    .ant-form-item-control-input {
        .ant-input-affix-wrapper {
            height: 44px;
            .ant-input {
                height: 100%;
            }
        }
    }
}
