@import '/src/styles/common/variables';

.converastion-detail-footer {
    height: rem(124);
    padding: rem(16);
    border-top: rem(1) solid $gray-10;
    height: revert;
    .icon-action {
        width: rem(20);
        height: rem(20);
        cursor: pointer;
    }
    .ant-image {
        height: fit-content;
    }
    .conversation-action {
        margin-top: rem(24);
        display: flex;
    }

    .conversation-input {
        resize: none;
        overflow: auto;
    }

    .file-container {
        img {
            border-radius: 10px;
        }
        .document-box {
            border-radius: 10px;
            background-color: $gray-100;
            height: fit-content;
        }
    }
}
