@import '/src/styles/common/variables';

.helpdesk-action-container {
    .helpdesk-action-row {
        border-bottom: 1px solid $gray-10;
        padding: rem(16) rem(24);
        height: fit-content;

        .helpdesk-action-col-flex {
            display: flex;
            .helpdesk-action-avatar {
                margin-left: rem(12);
                min-width: rem(40);
                min-height: rem(40);
                &.ant-avatar-lg.ant-avatar-icon {
                    font-size: unset;
                }
                .ant-avatar {
                    background-color: $primary;
                }
            }

            .helpdesk-action-user-name {
                @include text-style(500, rem(16), rem(24), $gray-900);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                max-width: 70%;
            }

            .helpdesk-action-user-email {
                @include text-style(500, rem(16), rem(24), $gray-500);
            }

            .action-button {
                height: rem(40);
                border-radius: rem(8);

                &.cancel-btn {
                    margin-right: rem(12);
                }
            }

            .icon-action {
                height: rem(20);
                width: rem(20);

                margin-left: rem(20);
            }

            .info-container {
                .partner-name {
                    font-size: rem(14);
                    line-height: rem(21);
                    color: $neutral-500;
                    font-weight: 500;
                    padding: rem(2) rem(10);
                    background-color: $neutral-10;
                    border-radius: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
            }
        }
    }
}
