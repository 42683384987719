@import '@styles/common/variables';

.notification-page-index {
    .type-notification {
        display: flex;
        gap: 8px;
        flex-direction: column;

        .item-type {
            width: 232px;
            height: 40px;
            padding: 8px 12px;
            border-radius: 6px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #22313f;
            border: none;

            &::before {
                display: none;
            }

            .module-item-wrapper {
                height: 100%;
            }

            .label-module {
                .label-item {
                    @include ellipsis(1);
                    max-width: 70%;
                }

                .total-notification {
                    .ant-badge-count {
                        background: var(--secondary) !important;
                        color: var(--primary) !important;
                        font-weight: 400;
                    }
                }
            }
        }

        .ant-radio-button-wrapper-checked {
            border-color: #d9d9d9;
            box-shadow: none;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary) !important;
            border-right-color: #d9d9d9 !important;
        }
    }

    .list-notification-wrapper {
        .ant-table {
            border-bottom: 1px solid #eaecf0;
            .ant-table-cell {
                padding: 0;
                border-bottom: none;
            }

            .notification-item {
                padding: 16px 24px;

                &.unread {
                    background: var(--secondary) !important;
                }

                .action-icon {
                    top: 50%;
                    bottom: 50%;
                    right: 12px;
                }

                .title-notification {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #22313f;
                }

                .create-date {
                    margin-top: 8px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #a7adb2;
                }
            }
        }

        .notification-pagination {
            border: none;
            padding-right: 16px;
        }
    }

    .filter-notification {
        .ant-radio-button-wrapper-checked {
            background-color: var(--secondary);
            border-color: var(--secondary);
            color: var(--primary);
            border-right-color: var(--secondary) !important;
            &:focus-within {
                box-shadow: none;
            }
        }
        .ant-radio-button-wrapper {
            width: 100px;
            height: 40px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border-left-width: 1px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            &:hover {
                color: var(--primary);
            }

            &::before {
                display: none;
            }
        }
    }

    .btn-mark-all-as-read {
        height: 40px;
    }
}
