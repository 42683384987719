@import '@styles/common/variables';

.select-partner.ant-select {
    .ant-select-selector,
    .ant-select-selection-item,
    .ant-select-selection-placeholder,
    .ant-select-selection-search-input,
    .ant-select-selector {
        height: 44px;
        width: 100%;
        line-height: 42px;
    }
}
