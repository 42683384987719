@import '../../../styles/common/variables';
.tenant-setting-containers {
    box-shadow: 0 rem(6) rem(18) rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    .tenant-setting-header-row {
        height: rem(84);
        border-bottom: rem(1) solid $neutral-divider;
        .tenant-setting-header-col {
            display: flex;

            .tenant-setting-header-title {
                @include text-style(500, rem(18), rem(28), $neutral-black);
            }
        }
    }
    .tenant-setting-form-row {
        .tenant-setting-form-item {
            &-label {
                color: $help-gray;
                font-weight: bold;
            }
        }
    }
    .tenant-setting-button-group {
        margin-top: rem(64);
        justify-content: flex-end;
    }
}
