.sa-revenue-page-wrapper {
    .title-section {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 16px;

        &.title-goal-chart {
            margin-bottom: 0;
        }
    }

    .filter-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .filter-range-date {
            margin-bottom: 20px;

            .ant-tag {
                background-color: var(--secondary) !important;
                color: var(--primary) !important;
                border-color: var(--primary);
            }
        }



        .filter-by-time {
            margin-bottom: 20px;


            .select-time-filter {
                .ant-radio-button-wrapper {
                    border: none;
                    width: 93px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #667085;
                    text-align: center;

                    &.ant-radio-button-wrapper-checked {
                        background-color: var(--secondary) !important;
                        color: var(--primary) !important;
                        background: #EFF8FF;
                        border-radius: 6px;
                        box-shadow: none;
                    }

                }

                .ant-radio-button-wrapper::before {
                    width: unset;
                    display: none;
                    height: 0px;
                }

            }
        }
    }

    .filter-by-application {
        max-width: 344px;

        .ant-select {
            width: 100%;
        }
    }
}