@import '../../../styles/common/variables';
.invite-user-drawer-container {
    .ant-drawer-content-wrapper {
        width: rem(800) !important;
    }

    .ant-input-affix-wrapper {
        padding: 0 11px;
    }

    .invite-user-button {
        height: rem(40);
    }

    .invite-user-form {
        .invite-user-form-item {
            .add-role-button {
                height: fit-content;
                width: fit-content;
                padding: rem(2);
            }

            &.role {
                label {
                    width: 100%;
                }
            }
        }
    }

    .ant-input {
        min-height: 44px;
    }

    .ant-select-selector {
        min-height: 44px;
        display: flex;
        align-items: center;
    }
}

.add-role-drawer-container {
    .ant-drawer-content-wrapper {
        width: rem(500) !important;
    }

    .save-role-button {
        height: rem(40);
    }
}
