@import '@styles/common/variables';

.item-list-container {
    .list-header {
        @include max-size(577px) {
            align-items: normal;
            flex-direction: column;
            gap: 8px;
        }
        display: flex;
        justify-content: space-between;
        padding: 1rem 0rem;
        align-items: center;

        .btn-add-new {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid transparent;
            padding: 0.625rem 1.25rem;
            border-radius: 0.375rem;

            @include max-size(577px) {
                width: 100%;
            }
        }

        .ant-select-selector {
            min-width: 200px;
        }
        .ant-select {
            @include max-size(577px) {
                width: 100%;
            }
        }
    }

    .size-price {
        padding-top: 0px;
    }

    .item-name {
        color: #4e5a65;
        font-weight: 500;
        @include ellipsis(1);
    }

    .pd-0 {
        padding: 0;
    }

    .ant-avatar-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
    }

    .pagination-container {
        border: 1px solid #eaecf0;
        border-top: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.contact-dropdown-container {
    .ant-dropdown-menu-item {
        height: 44px;

        .ant-btn {
            padding: 0;
        }

        .anticon svg {
            font-size: 14px !important;
        }

        .ant-dropdown-menu-title-content {
            font-size: 14px !important;
        }

        .ant-btn {
            border: 0;
            background: transparent;
        }
    }
}

.contact-property-container {
    border-left: 1px solid #eaecf0;
    padding: 24px;
    font-size: 16px;
    line-height: 24px;

    h4 {
        padding-top: 8px;
        padding-bottom: 8px;
        margin: 0;
        color: #344054;
    }

    .deals-box {
        border: 1px solid #eaecf0;
        border-radius: 10px;
        padding: 16px 0;
        display: flex;
        flex-direction: column;

        .deal-priority-wrapper {
            padding: 0 16px;

            .deal-priority {
                border-radius: 16px;
                font-weight: 500;
                border: transparent;

                .ant-badge {
                    padding: 4px;
                }

                &.high-priority {
                    background: #fef6ee;

                    .ant-badge-status-text {
                        color: #b93815;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }
            }
        }

        .deal-title {
            padding: 12px 16px;
            margin: 0;
        }

        .deal-detail {
            padding: 16px;
            border-top: 1px solid #eaecf0;
            border-bottom: 1px solid #eaecf0;
            display: flex;
            justify-content: space-between;

            .deal-price {
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                font-size: 14px;
                color: #12b76a;
            }

            .deal-date {
                font-size: 14px;
                color: #667085;
            }
        }

        .deal-box-footer {
            padding: 16px;
        }
    }

    .quotes-container {
        border: 1px #667085 solid;
        padding: 12px;

        .quote-item {
            font-weight: 500;

            .quote-item-heading {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;

                .quote-number {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            .quote-date {
                color: #667085;
            }
        }
    }

    .attachments-container {
        .attachment-item {
            box-sizing: border-box;
            background: #eaecf0;
            border: 1px solid #667085;
            padding: 20px 15px;

            .attachment-info {
                svg {
                    height: 16px;
                    width: auto;
                }
            }
        }
    }

    .subscriptions-container {
        .subscription-item {
            box-sizing: border-box;
            background: #eaecf0;
            border: 1px solid #667085;
            padding: 12px;

            .subscription-status {
                border-radius: 16px;
                font-weight: 500;
                border: transparent;

                .ant-badge {
                    padding: 4px;
                }

                &.active-status {
                    background: #ecfdf3;

                    .ant-badge-status-text {
                        color: #027a48;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }

                &.paused-status {
                    background: rgba(255, 186, 25, 0.19);

                    .ant-badge-status-text {
                        color: #ffba19;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }

                &.cancelled-status {
                    background: rgba(71, 84, 103, 0.28);

                    .ant-badge-status-text {
                        color: #667085;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }
            }

            .subscription-price {
                font-weight: 500;
                font-size: 24px;
                color: #1570ef;
            }

            .subscription-summary {
                margin-top: 12px;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #000000;
            }
        }
    }
}

.contact-timeline-container {
    .ant-tabs-tab {
        .ant-tabs-tab-btn {
            color: #818e9b;

            svg path {
                fill: #818e9b;
            }

            span {
                padding-left: 5px;
            }
        }

        &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: #334d6e;

                svg path {
                    fill: #334d6e;
                }
            }
        }
    }

    .upload-attachment-wrapper {
        min-height: min-content;
    }
}

.contact-information {
    border: 1px solid #e9eaec;
    border-radius: 8px;
    padding: 30px 20px;
    margin-bottom: 24px;
    .ant-btn-primary {
        width: 100% !important;
    }
    .basic-information {
        .avatar {
            width: 84px;
            height: 84px;

            .ant-avatar {
                width: 84px;
                height: 84px;
                border-radius: 50%;
            }
        }
        .contact-social {
            @include max-size(577px) {
                flex-direction: row;
            }
            a svg path {
                fill: #109cf1;
            }
        }

        .full-name {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #22313f;
            @include ellipsis(2);
        }

        .email-contact {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #22313f;
            @include ellipsis(2);
        }

        .edit-profile {
            width: 160px;
            height: 40px;
            display: flex;
            justify-content: center;
            margin-top: 26px;
        }
    }

    .about-contact {
        @include max-size(479px) {
            .ant-row {
                flex-direction: column;

                .ant-col {
                    flex: 1;
                    max-width: 100%;
                    .break-words {
                        overflow-wrap: unset;
                    }
                }
                .value {
                    margin-bottom: 1rem;
                }
                .invisible-wrap {
                    display: none;
                }
            }
        }
        padding-right: 30px;

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #22313f;
            margin-bottom: 20px;
        }

        .divider-line {
            width: 1.5px;
            height: 140px;
            background-color: #eaecf0;
            top: 11px;
        }

        .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #7a838c;
        }

        .value {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #22313f;
            @include ellipsis(2);
        }
    }
}

.contact-note {
    background: #f4f5f5;
    border-radius: 8px;
    padding: 16px;
}

.list-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #22313f;
}

.insight {
    border-radius: 8px;
    padding: 16px;
}
