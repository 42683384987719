@import '@styles/common/variables';

.disputeDetail {
    margin: auto;

    @media (max-width: 768px) {
        padding: 8px;
    }
    .headerDispute {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;
    }
    .textLarge {
        font-size: 24px;
        font-weight: bold;

        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    .headerDisputeTitle {
        color: $neutral-80;
        font-size: 12px;
    }

    .responseContainer {
        background-color: #fff7e6;
        border: 1px solid #ffbb96;
        border-radius: 8px;

        position: relative;
        margin-top: 20px;
        width: 100%;
    }

    .responseHeader {
        font-weight: 700;
        font-size: 18px;
        color: #262626;
    }

    .responseSubHeader {
        font-weight: 700;
        font-size: 14px;

        margin-top: 4px;
    }

    .responseTime {
        position: absolute;
        right: 20px;
        top: 20px;
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 500;
    }

    .uploadContainer {
        background-color: #ffffff;
        border: 1px solid #d9d9d9;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 16px;
        margin-top: 16px;
    }

    .uploadContainerCustom {
        background-color: transparent;
        border: none;
        padding: 0;
    }

    .uploadHeader {
        font-weight: 700;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    .uploadInstructions {
        margin-top: 4px;
        font-size: 14px;
    }

    .uploadArea {
        margin-top: 0.3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px dashed #d9d9d9;
        padding: 8px;
        border-radius: 4px;
        color: #595959;
        cursor: pointer;
        text-align: center;
    }

    .uploadArea {
        :global {
            .ant-upload {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                flex-direction: row;
            }
        }
    }

    .uploadText {
        padding-left: 0.5rem;
        font-size: 14px;
        color: #595959;
    }

    .uploadInstructionsBelow {
        color: #8c8c8c;
        font-size: 12px;
        text-align: center;
        margin-top: 8px;
    }

    .responseFooter {
        background-color: #f0f0f0;
        padding: 10px;
        border-radius: 4px;
        font-size: 14px;
    }

    .acceptLink {
        color: #1890ff;
        font-weight: 500;
        text-decoration: underline;
    }
    .sectionDisputeContainer {
        padding: 20px 0;
        background-color: #ffffff;
        .sectionDisputeDetail {
            padding: 0 24px;
            margin-bottom: 20px;
            .sectionDisputeTitle {
                font-weight: bold;
                color: #262626;
                margin-bottom: 12px;
            }
            .sectionDisputeRow {
                margin-bottom: 16px;
            }
            .sectionDisputeLabel {
                margin-bottom: 16px;
            }
            .sectionDisputeValue {
                margin-bottom: 16px;
            }
        }
    }
    .textDate {
        font-size: 12px;
    }

    :global {
        .ant-spin-nested-loading {
            width: 100%;
        }
    }
}

.customOverlayDispute {
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    :global {
        .ant-tooltip-inner {
            background-color: #fff !important;
            color: #000;
        }
    }

    .tooltipHeader {
        margin-bottom: 4px;
        color: #000;
        display: block;
    }
    .tooltipSubheader {
        color: #393939;
        font-weight: 500;
        font-size: 12px;
    }
}

.disputeFlexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.disputeFlexCol {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.disputeTextBold {
    font-weight: bold;
}

.disputeBlock {
    display: block;
    text-align: right;
}

.disputeBlockFlex {
    display: flex;
    text-align: right;
    align-items: center;
    font-weight: 600;
}

.disputeAdjustmentsTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.disputeTooltipText {
    display: block;
    margin-top: 8px;
    font-size: 12px;
}

.disputeTooltipTextGrey {
    color: #595959;
    font-size: 12px;
}

.modalDisputeAccept {
    .buttonDisputeAccept {
        background-color: $primary !important;
        border: 1px solid $primary;
        color: #ffffff;
        font-weight: bold;
        border: none;
        border-radius: 4px;
    }
    :global {
        .ant-btn-primary:disabled {
            opacity: 0.5;
            background-color: $primary;
            border: 1px solid $primary;
            color: #ffffff;
        }

        .ant-btn-default:not(:disabled):hover {
            border-color: $primary;
        }

        .ant-btn-primary:not(:disabled):hover {
            background-color: $primary !important;
            border: 1px solid $primary;
            color: #ffffff;
            font-weight: bold;
            border: none;
            border-radius: 4px;
        }
        .ant-btn > span {
            font-size: 14px;
        }
    }
}

.collapseIcon {
    font-size: 12px;
}

.collapseButton {
    background-color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;
    font-weight: bold;
    height: 40px;
}

.customTooltipLink {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #f0f0f0;
}

.customTooltip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #f0f0f0;
    font-weight: 700;
    .copyCode {
        margin-left: 16px;
        font-size: 12px;
        color: #f0f0f0;
        white-space: nowrap;
        background-color: inherit;
        cursor: pointer;
        font-weight: normal;
    }
}

.headerColCustom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.responseContainer {
    padding: 16px;

    :global {
        .ant-card-body {
            @media (max-width: 576px) {
                padding: 0;
            }
        }
    }

    .disputeFlexRow {
        flex-direction: row;
        @media (max-width: 576px) {
            flex-direction: column;
        }
    }

    .responseTime {
        font-size: 0.875rem;
        @media (max-width: 576px) {
            margin-top: 8px;
            position: static;
        }
    }

    .uploadArea {
        @media (max-width: 576px) {
            text-align: center;
        }

        :global {
            .ant-upload {
                @media (max-width: 576px) {
                    flex-direction: column;
                }
            }
        }
    }
}

.submitForm {
    padding: 16px 24px;
    @media (max-width: 576px) {
        padding: 0;
    }
}

.titleTable {
    font-weight: bold;
    color: #262626;
    margin-bottom: 12px;
}

.uploadAreaDisabled {
    border: 2px dashed #d9d9d9;
    padding: 16px;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    color: #bfbfbf;
    cursor: not-allowed;
}

.maxFilesMessage {
    font-size: 14px;
    color: #595959;
    display: flex;
    align-items: center;
    justify-content: center;
}

.maxFilesMessageIcon {
    font-size: 18px;
    color: #1890ff;
    margin-right: 8px;
}

.noteText {
    font-size: 12px;
    color: #595959;
    margin-top: 8px;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6); // semi-transparent background
    backdrop-filter: blur(5px); // adds the blur effect
    z-index: 1000; // ensure it's on top of other content
}
