.ant-upload-picture-card-wrapper {
    .ant-upload-list-picture-card-container {
        display: none;
    }
    .ant-upload.ant-upload-select-picture-card {
        width: unset;
        height: unset;
        margin-right: unset;
        margin-bottom: unset;
        background-color: unset;
        border: unset;
    }
    .ant-upload-list-picture-card-container {
        .ant-upload-list-item-actions {
            a {
                display: none;
            }
            .ant-btn.ant-btn-sm {
                padding: unset;
            }
        }
    }
}
