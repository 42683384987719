@import '@styles/common/variables';

.sa-subscription-page-wrapper {
    .search-item {
        margin-right: 32px;

        &.custom-width {
            width: 212px;
        }
    }
}

.icon-action-subscription-list {
    display: flex;
    align-items: center;

    svg {
        margin-right: 8px;
    }
}

.application-subscription-page-wrapper {
    .filter-range-date {
        .ant-tag {
            background-color: var(--secondary) !important;
            color: var(--primary) !important;
            border-color: var(--primary);
        }
    }
}

.create-subscription-container {
    border: 1px solid #eaecf0;
    .ant-form {
        padding: 32px 0;
    }

    .ant-btn-primary {
        background: var(--primary);
        border-color: var(--primary);
    }

    .ant-btn-dashed {
        color: var(--primary);
        border-color: var(--primary);
        border-style: inherit;
    }

    .block-heading {
        color: #101828;
        padding: 26px 32px;
        border-bottom: 1px solid #eaecf0;
        font-weight: 500;
        font-size: 20px;
        display: flex;
        align-items: center;
        .header-actions {
            margin-left: auto;
            .ant-btn {
                margin-left: 16px;
            }
        }
    }

    .section {
        margin: 0px 32px;
        padding: 24px 24px 0px;
        border: 1px solid #eaecf0;
        .section-header {
            font-weight: 500;
            font-size: 20px;
            color: #101828;
            margin-bottom: 12px;
        }

        .ant-form-item-label > label {
            color: #344054;
            font-weight: 500;
            font-size: 16px;
        }

        .table-header {
            background: #f9fafb;
            border-bottom: 1px solid #eaecf0;
            > div {
                font-weight: 500;
                font-size: 14px;
                color: #667085;
                padding: 10px 24px;
            }
        }

        .btn-add-container {
            display: flex;
            margin: 10px 0;
            .ant-btn {
                margin-left: auto;
                width: inherit;
                border: 0 !important;
                color: var(--primary);
                box-shadow: none;
            }
        }

        .ant-picker,
        .ant-input,
        .ant-input-number,
        .ant-select-selector,
        .ant-select-selection-item,
        .ant-select-selection-placeholder,
        .ant-select-selection-search-input {
            height: 44px;
            width: 100%;
            line-height: 42px;
        }
    }

    .date-title {
        color: #667085;
        font-weight: 500;
        font-size: 16px;
        margin-right: 30px;
    }

    .plan-service-wrapper {
        width: 100%;
        .plan-service-header {
            .plan-title {
                @include text-style(500, rem(20), rem(30), $gray-900);
            }
        }
    }
}

.delete-button {
    border: 1px solid $danger;
    color: $danger;
}
