@import '../../../styles/common/variables';

.setting-color-containers {
    background-color: $white;
    .tenant-setting-header-row {
        height: rem(84);
        border-bottom: rem(1) solid $neutral-divider;
        .tenant-setting-header-col {
            display: flex;

            .tenant-setting-header-title {
                @include text-style(500, rem(18), rem(28), $neutral-black);
            }
        }
    }
    .tenant-setting-form-item {
        &-label {
            font-weight: 500;
            font-size: 18px;
        }
        &-color-picker {
            box-shadow: unset !important;
            border: 1px solid $gray-200;
        }
        // .tenant-setting-form-item {
        //     &-label {
        //         color: $help-gray;
        //         font-weight: bold;
        //         margin-bottom: 0;
        //     }

        // }
    }
    .button-group {
        margin-top: rem(32);
        &-item {
            width: rem(160);
            background-color: $accent-default;
            color: $white;
            justify-content: center;
            padding: rem(11) rem(14);
            font-size: 13px;
            font-weight: 600;
        }
    }
}
.ant-space-item {
    width: 100%;
}
