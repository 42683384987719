@import '@styles/common/variables';

.custom-search {
    color: $neutral-40;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #d0d5dd;
    background: #ffffff;
    padding: 8px;
    line-height: 26px;
    .ant-input {
        margin-left: 12px;
    }
}
