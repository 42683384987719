.image-upload {

    .upload {
        width: 100%;
        position: absolute;
        bottom: 10px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        .btn-upload {
            padding: 1.5rem 1rem;
            display: flex;
            align-items: center;
            color: #000;
            background: hsla(0, 0%, 100%, 0.4);
            border: none;
            font-size: 14px;
            font-weight: 600;
            overflow: hidden;
            word-wrap: normal;
            text-shadow: 0 0 5px rgb(0 0 0 / 25%);

            .anticon {
                font-size: 24px;
                font-weight: 700;
            }
        }
    }
}

.horizon-upload-button {
    display: flex;

    img {
        width: 96px;
        height: 96px;
        margin-right: 16px;
    }

    .upload {
       
        display: flex;
        flex-direction: column;
        padding-top: 15px;

        .text-add {
            display: block;
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            /* identical to box height, or 24px */

            letter-spacing: -0.005em;

            /* Gray/700 */

            color: #344054;
        }

        button {
            background: #FFFFFF;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            height: 40px;
            width: 137px;
            display: flex;
            justify-content: center;
            padding: 8px 14px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #344054;
        }
    }
}