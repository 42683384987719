@import '@styles/common/variables';
@import '/src/styles/common/variables';

.open-url {
    color: var(--primary);

    svg {
        display: inline;
        margin-right: 6px;

        path {
            fill: var(--primary);
        }
    }

    .icon-open {
        width: 14px;
        height: 14px;
    }

    &:hover {
        color: var(--primary);
    }
}

.btn-load-more:active {
    color: #344054;
    border-color: #d9d9d9;
}
.btn-load-more:focus {
    color: #344054;
    border-color: #d9d9d9;
}
.btn-load-more:hover {
    color: #3f94fc;
    border-color: #3f94fc;
}
