@import '@styles/common/variables';

.chip {
    display: inline-block;
    .content {
        border-radius: 16px;
        padding: 2px 10px 2px 8px;
        font-size: 14px;
        display: flex;
        &.disable {
            background-color: $gray-100;
            .dot {
                background-color: $gray-700;
            }
            .label {
                color: $gray-700;
            }
        }
        &.success {
            background-color: $success-50;
            .dot {
                background-color: $success-700;
            }
            .label {
                color: $success-700;
            }
        }
        &.warning {
            background-color: $warning-50;
            .dot {
                background-color: $warning-700;
            }
            .label {
                color: $warning-700;
            }
        }

        .dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin: 9px 7px 0 0;
        }
    }
}
