@import '@styles/common/variables';
.contact-detail-form {
    .avatar {
        .ant-avatar {
            aspect-ratio: 1 / 1;
            width: 104px;
            height: unset;
            max-width: 100%;
        }
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;
        }
        .ant-upload-picture-card-wrapper {
            display: flex;
            justify-content: center;
            .ant-upload.ant-upload-select-picture-card {
                background-image: url(../../../../public/images/avatar-placeholer.png);
                border-radius: 50%;
                border: none;
                aspect-ratio: 1 / 1;
                width: 104px;
                height: unset;
                max-width: 100%;
                margin: 0;
                & > .ant-upload {
                    img {
                        border-radius: 50%;
                    }
                }
                .upload {
                    .btn-upload {
                        display: none;
                    }
                }
            }
        }
    }
    .full-name {
        text-align: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 23px;
    }
    .company {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }
    .btn-group {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        button {
            font-size: 11px;
            line-height: 16px;
            height: 22px;
        }

        .edit-cancel {
        }
        .edit-submit {
        }
    }

    .contact-social {
        a svg path {
            fill: #109cf1;
        }
    }
    .ant-form-item:focus-within {
        .ant-form-item-label {
            label {
                color: #109cf1;
            }
        }
    }
    .ant-form-item-label {
        padding-bottom: 0;
        label {
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            color: #818e9b;
        }
    }
    .ant-input {
        min-height: 44px;
    }
    &.show-mode {
        label.ant-form-item-required::before {
            display: none;
        }
        .ant-form-item {
            margin-bottom: 14px;
        }
    }
}
