@import '/src/styles/common/variables';

.ticket-message-container {
    padding: 24px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    .infinite-scroll-component {
        overflow: hidden !important;
    }
    .conversation-card-avatar {
        .ant-avatar {
            display: flex;
            align-items: center;
            background-color: $primary;
        }
        .tail-item {
            height: 100%;
            text-align: center;
            &::after {
                content: '';
                display: inline-block;
                width: rem(2);
                background-color: $gray-200;
                height: 100%;
            }
        }
    }

    .conversation-card-content {
        width: 100%;
        .header-conversation-content {
            padding: rem(8) rem(14);
            border: rem(1) solid $gray-200;
            .name-card {
                @include text-style(500, rem(16), rem(24), $gray-700);
            }
            .time-card {
                @include text-style(400, rem(14), rem(21), $gray-500);
            }
        }
        .message-container {
            padding: rem(8) rem(14);
            border: rem(1) solid $gray-200;
            white-space: pre-line;
            @include text-style(400, rem(16), rem(24), $gray-500);
            .message-card {
                border-radius: 0 8px 8px 8px;
                a {
                    text-decoration: underline;
                }
            }
            .file-container {
                .ant-image {
                    width: 100%;
                    img {
                        width: auto;
                        height: 350px;
                    }
                }
            }
        }
    }
    .last-tail-item {
        display: inline-block;
        background-color: $gray-200;
        height: rem(3);
        width: 100%;
        padding: 1px 20px;
        margin: -6px 20px;
    }
}
