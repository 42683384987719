@import '@styles/common/variables';
@import '@styles/common/mixins';

.contact-detail-form {
    .avatar {
        .ant-avatar {
            aspect-ratio: 1 / 1;
            width: 104px;
            height: unset;
            max-width: 100%;
        }
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;
        }
        .ant-upload-picture-card-wrapper {
            display: flex;
            justify-content: center;
            .ant-upload.ant-upload-select-picture-card {
                background-image: url(../../../../../public/images/avatar-placeholer.png);
                border-radius: 50%;
                border: none;
                aspect-ratio: 1 / 1;
                width: 104px;
                height: unset;
                max-width: 100%;
                margin: 0;
                & > .ant-upload {
                    img {
                        border-radius: 50%;
                    }
                }
                .upload {
                    .btn-upload {
                        display: none;
                    }
                }
            }
        }
    }
    .full-name {
        text-align: center;
        @include text-style(500, rem(15), 23px);
    }
    .company {
        text-align: center;
        @include text-style(400, rem(12), 18px);
    }
    .btn-group {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        button {
            height: 22px;
            @include text-style(400, rem(12), 16px);
        }

        .edit-cancel {
        }
        .edit-submit {
        }
    }

    .ant-form-item:focus-within {
        .ant-form-item-label {
            label {
                color: $accent-default;
            }
        }
    }
    .ant-form-item-label {
        padding-bottom: 0;
        label {
            @include text-style(500, rem(11), 16px, $help-gray);
        }
    }
    .ant-input {
        min-height: 44px;
    }
    &.show-mode {
        label.ant-form-item-required::before {
            display: none;
        }
    }
}

.contact-note-list-container {
    .ant-list-items > * {
        margin-bottom: 10px;
    }
}

.note-item-actions-dropdown {
    .ant-dropdown-menu-item {
        height: 44px;

        .ant-btn {
            padding: 0;
        }

        .anticon svg {
            font-size: 18px;
        }

        .ant-dropdown-menu-title-content {
            font-size: 16px;
        }

        .ant-btn {
            border: 0;
            background: transparent;
        }
    }
}

.upload-list-inline {
    .ant-upload-list-item {
        float: left;
        width: 200px;
        margin-right: 8px;
    }
}

.btn-add-note {
    height: 32px;
    border-radius: 4px;
    font-style: normal;
    @include text-style(500, rem(16), 24px, $white);
}

.note-list-wrapper {
    background: $white;
    border-radius: 8px;

    .contact-note-list {
        .title-note {
            font-style: normal;
            @include text-style(700, rem(16), 24px, $neutral-500);
        }
    }
}

.note-list-wrapper {
    .ant-space {
        flex-direction: row;
    }
}

.list-content {
    border: 0.5px solid #eaecf0;
    border-radius: 8px;
}
