@import '@styles/common/variables';

.filterRangeDate {
    :global {
        .ant-picker,
        .ant-picker:hover,
        .ant-picker-focused {
            height: 46px !important;
        }
    }
    .selectRangeDate {
        .ant-tag {
            background-color: var(--secondary);
            color: var(--primary);
            border-color: transparent;
        }
    }
}

.errorRange {
    :global {
        .ant-picker,
        .ant-picker:hover,
        .ant-picker-focused {
            border-color: $status-error-3;
            box-shadow: none;
            height: 46px !important;
        }
    }
    p {
        color: $status-error-3;
    }
}
