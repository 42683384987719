@import '@styles/common/variables';
.invoice-container {
    border: 1px solid #eaecf0;
    .ant-form {
        padding: 32px 0;
    }

    .service-hide {
        display: none;
    }

    .unit-product {
        display: flex;
        flex-direction: column;
    }

    .ant-btn-primary {
        background: var(--primary);
        border-color: var(--primary);
    }

    .ant-btn-dashed {
        color: var(--primary);
        border-color: var(--primary);
        border-style: inherit;
    }

    .details-section {
        @include max-size(577px) {
            .ant-row {
                flex-direction: column;
            }
            .user-info-col {
                margin-bottom: 1.5rem;
            }
        }
    }

    .block-heading {
        color: #101828;
        padding: 26px 32px;
        border-bottom: 1px solid #eaecf0;
        font-weight: 500;
        font-size: 20px;
        display: flex;
        align-items: center;
        .header-actions {
            margin-left: auto;
            @include max-size(577px) {
                flex-direction: column;
                display: flex;
                margin-left: 0;
                margin-top: 16px;
                gap: 8px;
                width: 100%;
                .ant-btn {
                    margin-left: 0;
                    margin-right: 16px;
                }
            }
            .ant-btn {
                margin-left: 16px;
            }
        }

        @include max-size(577px) {
            flex-direction: column;
            align-items: baseline !important;
            .header-actions {
                margin-left: 0 !important;

                .ant-btn {
                    margin-left: 0 !important;
                    margin-right: 16px;
                }
            }
        }
    }

    .section {
        margin: 0px 32px;
        padding: 24px 24px 0px;
        border: 1px solid #eaecf0;

        @include max-size(577px) {
            margin: 0 16px !important;
        }
        .section-header {
            font-weight: 500;
            font-size: 20px;
            color: #101828;
            margin-bottom: 12px;
        }

        .ant-form-item-label > label {
            color: #344054;
            font-weight: 500;
            font-size: 16px;
        }

        .table-header {
            background: #f9fafb;
            border-bottom: 1px solid #eaecf0;
            > div {
                font-weight: 500;
                font-size: 14px;
                color: #667085;
                padding: 10px 24px;
                @include max-size(777px) {
                    padding: 10px 0px;
                }
            }
            @include max-size(577px) {
                display: none;
            }
        }

        .list-product-item {
            border: 1px solid #eaecf0;
            > div.ant-col {
                padding: 14px 24px 0 24px;
                @include max-size(577px) {
                    padding: 10px;
                }
            }
            .unit-charge {
                display: flex;
                flex-direction: column;
            }
        }

        .btn-add-container {
            display: flex;
            margin: 10px 0;
            .ant-btn {
                margin-left: auto;
                width: inherit;
                border: 0 !important;
                color: var(--primary);
                box-shadow: none;
            }
        }
        .sub-total {
            .total-container {
                display: flex;
                align-items: flex-start;
                height: 100%;
                margin-top: 16px;
                @include max-size(577px) {
                    margin-top: 0px !important;
                    margin-bottom: 1rem;
                }
            }
        }

        .ant-picker,
        .ant-input,
        .ant-input-number,
        .ant-select-selector,
        .ant-select-selection-item,
        .ant-select-selection-placeholder,
        .ant-select-selection-search-input {
            height: 44px;
            width: 100%;
            line-height: 42px;
        }

        .item-list {
            border-bottom: 1px solid #eaecf0;
            margin-bottom: 1px;
        }
        .sum-total {
            border-top: 1px solid #eaecf0;
            padding: 24px 0;
            color: #101828;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .date-title {
        color: #667085;
        font-weight: 500;
        font-size: 16px;
        margin-right: 30px;
    }

    .details-section {
        padding: 24px;
        border-bottom: 1px solid #eaecf0;
        .section-header {
            font-weight: 500;
            font-size: 20px;
            color: #101828;
            margin-bottom: 12px;
        }

        .ant-form-item-label > label {
            color: #344054;
            font-weight: 500;
            font-size: 16px;
        }

        .table-header {
            background: #f9fafb;
            border-bottom: 1px solid #eaecf0;
            > div {
                font-weight: 500;
                font-size: 14px;
                color: #667085;
                padding: 10px 24px;
                @include max-size(777px) {
                    padding: 10px 0px;
                }
            }
        }

        .list-product-item {
            border: 1px solid #eaecf0;
            > div {
                padding: 14px 24px;
                @include max-size(577px) {
                    padding: 10px;
                }
            }
        }

        .total-container {
            display: flex;
            align-items: flex-start;
            height: 100%;
            margin-top: 8px;
            @include max-size(577px) {
                margin-top: 0px;
                margin-bottom: 1rem;
            }
        }

        .item-list {
            border-bottom: 1px solid #eaecf0;
            margin-bottom: 1px;
        }
        .sum-total {
            border-top: 1px solid #eaecf0;
            padding: 24px 0;
            color: #101828;
            font-weight: 500;
            font-size: 16px;
        }

        .user-info {
            color: #667085;
            font-weight: 400;
            font-size: 16px;
            .name {
                font-weight: 600;
                font-size: 18px;
                color: #344054;
            }
        }
    }

    .invoice-items-list {
        display: none;
    }
}

.payment-modal {
    .payment-item {
        background: #f4f5f5;
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        &.active {
            border: 1px solid $primary;
        }

        .card-no {
            font-weight: 700;
            font-size: 16px;
            color: #22313f;
        }

        .card-img {
            img {
                height: 24px;
            }
        }
    }

    .new-card {
        font-weight: 500;
        font-size: 16px;
        color: #22313f;
        margin-top: 5px;
    }

    .ant-radio-group {
        width: 100%;
    }

    .ant-radio-wrapper {
        display: flex;
        align-items: center;
    }

    .ant-radio-inner {
        width: 24px;
        height: 24px;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: var(--primary);
    }

    .ant-radio-inner::after {
        background-color: var(--primary);
        width: 28px;
        height: 28px;
        margin-top: -14px;
        margin-left: -14px;
    }

    .ant-radio-checked::after {
        border-color: var(--primary);
    }
}

.invoice-page-wrapper {
    .filter-wrapper {
        display: flex;

        .search-item {
            margin-right: 30px;
        }
    }

    .table-wrapper {
        .ant-table-row {
            cursor: pointer;
        }

        .invoices-table {
            .status {
                &-0 {
                    color: $black100;
                }
                &-1 {
                    color: $ant-active-text;
                }
                &-2,
                &-3 {
                    color: $success-700;
                }
                &-4 {
                    color: $error-700;
                }
            }
        }
    }
}

.invoice-tooltip {
    .ant-tooltip-inner {
        .ant-btn-link {
            color: $white;
        }
    }
}

.invoice-dropdown-container {
    .ant-dropdown-menu {
        display: flex;
        flex-direction: column;
    }

    .ant-dropdown-menu-item {
        height: 44px;
        a,
        .ant-btn {
            width: 100%;
        }

        .ant-btn {
            padding: 0;
        }

        .anticon svg {
            font-size: 18px;
        }

        .ant-dropdown-menu-title-content {
            font-size: 16px;
        }

        .ant-btn {
            border: 0;
            background: transparent;
        }
    }
}

@include max-size(768px) {
    .invoice-detail {
        height: 100%;
        padding-bottom: rem(94);
        overflow-y: auto;
        border-bottom: none;
        .block-heading {
            padding: 24px 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .date-container {
                display: flex;
                flex-direction: column;
            }
        }

        .date-wrapper {
            width: 100%;
        }

        .details-section {
            .user-info-col {
                width: 100%;
            }

            &.invoice-items-table {
                display: none;
            }
        }

        .invoice-items-list {
            display: flex;
            flex-direction: column;
            .ant-col {
                &.title {
                    span {
                        @include text-style(500, rem(16), rem(20), #101828);
                    }
                }

                &.value {
                    display: flex;
                    justify-content: flex-end;
                    span {
                        @include text-style(500, rem(16), rem(20), $gray-500);
                    }
                }
            }
        }

        .detail-actions {
            background-color: $white;
            width: 100%;
            position: fixed;
            bottom: 0;
        }
    }
}

.action__custom {
    .ant-btn:not(.ant-btn-icon-only) > .ant-btn-icon:not(:last-child) {
        margin-inline-end: 0px;
    }
}

.table-header-none {
    @include max-size(577px) {
        display: none;
    }
}

.item-custom {
    @include max-size(577px) {
        flex-direction: column;
        .ant-form-item {
            width: 100%;
            margin-bottom: 0;
        }
    }

    @include min-size(578px) {
        .ant-form-item-label > label {
            display: none;
        }
    }
}

.product-custom {
    @include min-size(578px) {
        .ant-form-item-label > label {
            display: block;
        }
    }
}

.total-wrapper {
    @include max-size(577px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-left: 1rem;
        padding-right: 1rem !important;
        border-top: 1px solid $neutral-10;
    }
}

.total-price {
    margin-bottom: 1rem;
    @include min-size(577px) {
        display: none;
    }
}

.unit-price-custom {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    @include max-size(577px) {
        margin-bottom: 0;
    }
}

.custom-label {
    display: none;
    @include max-size(577px) {
        color: #344054;
        font-weight: 500;
        font-size: 16px;
        display: block;
    }
}

.margin-custom {
    .ant-form-item-label {
        display: block !important;
    }
}

.custom-margin {
    .ant-form-item-label {
        @include min-size(577px) {
            padding-bottom: 16px;
        }
    }
}

.frequency-custom,
.total-custom {
    .ant-form-item-control-input {
        @include max-size(577px) {
            background-color: $neutral-10;
            height: 44px;
            line-height: 42px;
            border-radius: 6px;
        }
    }
}

.invoice-price-custom {
    @include max-size(577px) {
        background-color: $neutral-10;
        height: 44px;
        line-height: 42px;
        border-radius: 6px;
        padding: 0 10px;
    }
}
