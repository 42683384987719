@import '@styles/common/variables';
@import '@styles/common/media';

.onboarding-step-two-layout {
    background: #fff url('~/public/images/bg.jpg') no-repeat center bottom fixed !important;
    background-size: cover !important;
    height: 100%;
    .onboarding-container {
        margin: auto;
        height: 100%;
        width: 454px;

        @include tablet-and-phone {
            width: 100%;
            padding: 5px;
        }

        .ant-card {
            margin-top: 10%;
            .ant-card-head {
                padding: 32px 24px 0 24px;
            }
            .ant-card-body {
                padding: 0 24px 24px;
                .org-info {
                    text-align: center;
                }
            }
        }
        .ant-card-head-title {
            white-space: pre-wrap;
            text-align: center;
        }
    
        .title {
            color: $primary-bg-color;
            font-size: 40px;
            margin: 0px 0 5px;
            font-weight: 700;
            font-size: 32px;
            line-height: 42px;
        }
    
        .desc {
            color: $neutral-60;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 20px;
        }
    
        .ant-btn-primary {
            background: $primary;
            border: 0;
        }
        .ant-input, 
        .ant-select-selector{
            height: 54px;
            background: $background-input;
            border: 1px solid $neutral-10;
            border-radius: 4px;
            color: $neutral-40;
            .ant-select-selection-item,
            .ant-select-selection-placeholder {
                padding-top: 10px;
            }
        }
        .ant-form-item{
            margin-bottom: 16px;
        }

        // .ant-form-item-control-input-content{
        //     position: relative;
        //     .placeholder {    
        //         color: $neutral-40;
        //         position: absolute;
        //         top: 16px;
        //         left: 15px;
        //         display: block;
        //         &:after{
        //             position: relative;
        //             top: -3px;
        //             left: 1px;
        //             display: inline-block;
        //             content: "*";
        //             color: $danger;
        //         }
        //     }
        //     .ant-input:focus+.placeholder,
        //     .ant-input:valid+.placeholder,
        //     .ant-select.ant-select-focused .placeholder{
        //         display: none;
        //     }

        // }
    }
}