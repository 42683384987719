@import '/src/styles/common/variables';

.edit-helpdesk-container {
    height: calc(100vh - rem($container-position));
    .edit-helpdesk-col {
        border: rem(1) solid $gray-10;

        &.content-col {
            display: flex;
        }
    }

    .edit-helpdesk-row {
        &.edit-helpdesk-row-subject {
            height: rem(60);
            border-bottom: rem(1) solid $gray-10;
            .edit-helpdesk-subject-col {
                display: flex;
                .edit-helpdesk-subject-text {
                    @include text-style(500, rem(18), rem(27), $gray-700);
                }

                .mark-complete-button {
                    height: rem(40);
                    .mark-complete-icon {
                        height: rem(20);
                        width: rem(20);
                    }

                    color: $gray-500;
                }
            }
        }

        &.edit-helpdesk-row-messages {
            max-height: calc(100%);
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: rem(8);
            }

            &::-webkit-scrollbar-thumb {
                background-color: $neutral-40;
                border-radius: rem(16);
            }

            &::-webkit-scrollbar-track {
                background-color: $background-input;
                border-radius: rem(3);
            }
        }

        &.edit-helpdesk-row-footer {
            height: rem(124);
            padding: rem(16);
            border-top: rem(1) solid $gray-10;

            .edit-helpdesk-col-action {
                margin-top: rem(24);
                display: flex;

                .icon-action {
                    width: rem(20);
                    height: rem(20);
                    cursor: pointer;
                }
            }

            .edit-helpdesk-input {
                resize: none;
                height: rem(40);
                overflow-y: hidden;
            }
        }
    }
}
