@import '@styles/common/variables';

.invoice-container {
    border: 1px solid #eaecf0;
    .ant-form {
        padding: 32px 0;
    }

    .ant-btn-primary {
        background: var(--primary);
        border-color: var(--primary);
    }

    .ant-btn-dashed {
        color: var(--primary);
        border-color: var(--primary);
        border-style: inherit;
    }

    .block-heading {
        color: #101828;
        padding: 26px 32px;
        border-bottom: 1px solid #eaecf0;
        font-weight: 500;
        font-size: 20px;
        display: flex;
        align-items: center;
        .header-actions {
            margin-left: auto;
            .ant-btn {
                margin-left: 16px;
            }
        }
    }

    .section {
        margin: 0px 32px;
        padding: 24px 24px 0px;
        border: 1px solid #eaecf0;
        .section-header {
            font-weight: 500;
            font-size: 20px;
            color: #101828;
            margin-bottom: 12px;
        }

        .ant-form-item-label > label {
            color: #344054;
            font-weight: 500;
            font-size: 16px;
        }

        .table-header {
            background: #f9fafb;
            border-bottom: 1px solid #eaecf0;
            > div {
                font-weight: 500;
                font-size: 14px;
                color: #667085;
                padding: 10px 24px;
            }
        }

        .list-product-item {
            border: 1px solid #eaecf0;
            > div {
                padding: 14px 24px 0 14px;
                @include max-size(577px) {
                    padding: 10px;
                }
            }
        }

        .btn-add-container {
            display: flex;
            margin: 10px 0;
            .ant-btn {
                margin-left: auto;
                width: inherit;
                border: 0 !important;
                color: var(--primary);
                box-shadow: none;
            }
        }

        .total-container {
            display: flex;
            align-items: flex-start;
            height: 100%;
            margin-top: 8px;
        }

        .ant-picker,
        .ant-input,
        .ant-input-number,
        .ant-select-selector,
        .ant-select-selection-item,
        .ant-select-selection-placeholder,
        .ant-select-selection-search-input {
            height: 44px;
            width: 100%;
            line-height: 42px;
        }

        .item-list {
            border-bottom: 1px solid #eaecf0;
            margin-bottom: 1px;
        }
        .sum-total {
            border-top: 1px solid #eaecf0;
            padding: 24px 0;
            color: #101828;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .date-title {
        color: #667085;
        font-weight: 500;
        font-size: 16px;
        margin-right: 30px;
    }

    .details-section {
        padding: 24px;
        border-bottom: 1px solid #eaecf0;
        .section-header {
            font-weight: 500;
            font-size: 20px;
            color: #101828;
            margin-bottom: 12px;
        }

        .ant-form-item-label > label {
            color: #344054;
            font-weight: 500;
            font-size: 16px;
        }

        .table-header {
            background: #f9fafb;
            border-bottom: 1px solid #eaecf0;
            > div {
                font-weight: 500;
                font-size: 14px;
                color: #667085;
                padding: 10px 24px;
            }
        }

        .list-product-item {
            border: 1px solid #eaecf0;
            > div {
                padding: 14px 24px;
                @include max-size(577px) {
                    padding: 10px;
                }
            }
        }

        .total-container {
            display: flex;
            align-items: flex-start;
            height: 100%;
            margin-top: 8px;
        }

        .item-list {
            border-bottom: 1px solid #eaecf0;
            margin-bottom: 1px;
        }
        .sum-total {
            border-top: 1px solid #eaecf0;
            padding: 24px 0;
            color: #101828;
            font-weight: 500;
            font-size: 16px;
        }

        .user-info {
            color: #667085;
            font-weight: 400;
            font-size: 16px;
            .name {
                font-weight: 600;
                font-size: 18px;
                color: #344054;
            }
        }
    }
}

.invoice-page-wrapper {
    @include max-size(577px) {
        .filter-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
    .filter-wrapper {
        display: flex;

        .search-item {
            margin-right: 30px;
        }
    }
}
