@import '/src/styles/common/variables';

.helpdesk-list-container {
    .search-helpdesk {
        padding: rem(16) rem(20);
        .filter-wrapper {
            display: flex;

            .all-issues {
                @include text-style(500, rem(20), rem(28), $primary-text);
            }

            .show-all-button {
                height: rem(40);
                @include text-style(500, rem(16), rem(24), $gray-900);
                border-radius: rem(8);
                &.show-all-active {
                    background-color: $primary;
                    color: $white;
                    border: none;
                }
            }

            .show-all-button:focus {
                border-color: #d9d9d9;
            }
        }

        .search-input {
            height: rem(44);
        }
    }

    .helpdesk-list {
        max-height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: rem(8);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $neutral-40;
            border-radius: rem(16);
        }

        &::-webkit-scrollbar-track {
            background-color: $background-input;
            border-radius: rem(3);
        }

        .helpdesk-card {
            padding: rem(14) rem(20);

            &:hover {
                background-color: $ant-active-bg;
            }

            &.active-bg {
                background-color: $ant-active-bg;
            }

            .helpdesk-card-avatar {
                margin-left: rem(12);
                min-width: rem(40);
                min-height: rem(40);
                font-size: unset;
            }

            .helpdesk-card-content {
                .helpdesk-card-customer {
                    @include text-style(500, rem(16), rem(24), $gray-900);
                    @include ellipse-multi-lines(2);
                }

                .helpdesk-card-subject {
                    @include text-style(500, rem(16), rem(24), $gray-900);
                    @include ellipse-multi-lines(1);
                }

                .helpdesk-card-message {
                    @include text-style(500, rem(16), rem(24), $gray-500);
                    @include ellipse-multi-lines(1);
                }
            }
        }
    }

    .ticket-list {
        max-height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: rem(8);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $neutral-40;
            border-radius: rem(16);
        }

        &::-webkit-scrollbar-track {
            background-color: $background-input;
            border-radius: rem(3);
        }

        .conversation-card-active {
            background-color: $ant-active-bg;
        }

        .conversation-card {
            padding: rem(14) rem(20);

            &:hover {
                background-color: $ant-active-bg;
            }

            &.active-bg {
                background-color: $ant-active-bg;
            }

            .conversation-card-avatar {
                margin-left: rem(12);
                min-width: rem(40);
                min-height: rem(40);
                .ant-avatar {
                    background-color: $primary;
                    display: flex;
                }
            }

            .conversation-card-content {
                .status-container {
                    .status-text,
                    .priority-text {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 500;
                        padding: 2px 10px;
                        margin-right: 8px;
                        border-radius: 16px;
                    }

                    .status-text {
                        &.open {
                            color: $ant-active-text;
                            background-color: $ant-active-bg;
                        }

                        &.closed {
                            color: $success-700;
                            background-color: $success-50;
                        }
                    }
                    .priority-text {
                        .dot {
                            width: rem(6);
                            height: rem(6);
                            border-radius: 50%;
                            margin-right: rem(7);
                        }

                        &.critical {
                            color: $error-700;
                            background-color: $error-50;
                            .dot {
                                background-color: $error-500;
                            }
                        }

                        &.high {
                            color: $warning-700;
                            background-color: $warning-50;
                            .dot {
                                background-color: $warning-500;
                            }
                        }

                        &.normal {
                            color: $ant-active-text;
                            background-color: $ant-active-bg;
                            .dot {
                                background-color: $primary-500;
                            }
                        }

                        &.low {
                            color: $primary-text;
                            background-color: $gray-100;
                            .dot {
                                background-color: $gray-500;
                            }
                        }
                    }
                }
                .customer-name-card {
                    font-size: 14px;
                    font-weight: 500;
                    color: $gray-900;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
                .subject-name-card {
                    margin: 16px 0 4px 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: $gray-900;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .message-card {
                    font-size: 14px;
                    font-weight: 400;
                    color: $gray-500;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .message-time {
                    font-size: 12px;
                    font-weight: 400;
                    color: $gray-500;
                    margin-bottom: 0;
                    min-width: fit-content;
                }

                .partner-name {
                    font-size: rem(14);
                    line-height: rem(21);
                    color: $neutral-500;
                    font-weight: 500;
                    padding: rem(2) rem(10);
                    background-color: $neutral-10;
                    border-radius: 16px;
                    margin: rem(2) 0 rem(8);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
            }
        }
    }
}
