@import '/src/styles/common/variables';

.latest-notification-container {
    background-color: $neutral-10;
    border-radius: rem(16);
    padding: 24px;
    max-height: rem(450);
    overflow: auto;
    height: 450px;
    .title {
        font-size: rem(24);
        line-height: rem(32);
        font-weight: 700;
        color: $neutral-500;
    }

    .notification-list-container {
        .notification-list-wrapper {
            .notification-item {
                background-color: $white;
                border-radius: rem(8);
                padding: rem(16);
                cursor: pointer;
                .img-container {
                    margin-right: rem(16);
                    background-color: $neutral-10;
                    border-radius: 8px;
                    width: rem(40);
                    height: rem(40);
                    svg {
                        width: rem(40);
                        height: rem(40);
                    }
                }
            }
        }
    }
}
