@import '../../styles/common/variables';

.block-container {
    border: 1px solid #eaecf0;
    .ant-form {
        padding: 32px 0;
    }

    .unit-product {
        display: flex;
        flex-direction: column;
    }

    .ant-btn-primary {
        background: var(--primary);
        border-color: var(--primary);
    }

    .ant-btn-dashed {
        color: var(--primary);
        border-color: var(--primary);
        border-style: inherit;
    }

    .block-heading {
        color: #101828;
        padding: 26px 32px;
        border-bottom: 1px solid #eaecf0;
        font-weight: 500;
        font-size: 20px;
        display: flex;
        align-items: center;
        .header-actions {
            margin-left: auto;
            @include max-size(577px) {
                flex-direction: column;
                display: flex;
                margin-left: 0;
                margin-top: 16px;
                gap: 8px;
                width: 100%;
                .ant-btn {
                    margin-left: 0;
                    margin-right: 16px;
                }
            }
            .ant-btn {
                margin-left: 16px;
            }
        }

        @include max-size(677px) {
            flex-direction: column;
            align-items: baseline;
            .header-actions {
                margin-left: 0;
            }
            .ant-btn {
                margin-left: 0 !important;
                margin-right: 16px;
            }
        }
    }

    .section {
        margin: 0px 32px;
        padding: 24px 24px 0px;
        border: 1px solid #eaecf0;
        .section-header {
            font-weight: 500;
            font-size: 20px;
            color: #101828;
            margin-bottom: 12px;
        }

        .ant-form-item-label > label {
            color: #344054;
            font-weight: 500;
            font-size: 16px;
        }

        .table-header {
            background: #f9fafb;
            border-bottom: 1px solid #eaecf0;
            > div {
                font-weight: 500;
                font-size: 14px;
                color: #667085;
                padding: 10px 24px;
                @include max-size(777px) {
                    padding: 10px 0px;
                }
            }

            @include max-size(577px) {
                display: none;
            }
        }

        .list-product-item,
        .list-plan-item {
            border: 1px solid #eaecf0;
            > div {
                padding: 14px 0px 0 14px;
                @include max-size(577px) {
                    padding: 10px;
                }
            }
        }

        .btn-add-container {
            display: flex;
            margin: 10px 0;
            .ant-btn {
                margin-left: auto;
                width: inherit;
                border: 0 !important;
                color: var(--primary);
                box-shadow: none;
            }

            .add-quote-item-btn {
                color: $primary;
                @include text-style(700, rem(16), rem(24), var(--primary));
            }
        }

        .total-container {
            display: flex;
            align-items: flex-start;
            height: 100%;
            margin-top: 16px;
            @include max-size(577px) {
                margin-top: 0px;
                margin-bottom: 1rem;
            }
        }

        .ant-picker,
        .ant-input,
        .ant-input-number,
        .ant-select-selector,
        .ant-select-selection-item,
        .ant-select-selection-placeholder,
        .ant-select-selection-search-input {
            height: 44px;
            width: 100%;
            line-height: 42px;
        }

        .item-list {
            border-bottom: 1px solid #eaecf0;
            margin-bottom: 1px;
        }
        .sum-total {
            border-top: 1px solid #eaecf0;
            padding: 24px 0;
            color: #101828;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .date-title {
        color: #667085;
        font-weight: 500;
        font-size: 16px;
        margin-right: 30px;
    }

    .details-section {
        padding: 24px;
        .section-header {
            font-weight: 500;
            font-size: 20px;
            color: #101828;
            margin-bottom: 12px;
        }

        .ant-form-item-label > label {
            color: #344054;
            font-weight: 500;
            font-size: 16px;
        }

        .table-header {
            background: #f9fafb;
            border-bottom: 1px solid #eaecf0;
            > div {
                font-weight: 500;
                font-size: 14px;
                color: #667085;
                padding: 10px 24px;
                @include max-size(777px) {
                    padding: 10px 0px;
                }
            }

            @include max-size(577px) {
                display: none;
            }
        }

        .list-product-item,
        .list-plan-item {
            border: 1px solid #eaecf0;
            > div {
                @include max-size(577px) {
                    padding: 10px;
                }
                padding: 14px 24px;
            }
        }

        .total-container {
            display: flex;
            align-items: flex-start;
            height: 100%;
            margin-top: 8px;
        }

        .item-list {
            border-bottom: 1px solid #eaecf0;
            margin-bottom: 1px;
        }
        .sum-total {
            border-top: 1px solid #eaecf0;
            padding: 24px 0;
            color: #101828;
            font-weight: 500;
            font-size: 16px;
        }

        .user-info {
            color: #667085;
            font-weight: 400;
            font-size: 16px;
            .name {
                font-weight: 600;
                font-size: 18px;
                color: #344054;
            }
        }

        .payment-plans-title {
            @include text-style(700, rem(18), rem(24), $gray-900);
        }

        .payment-plan-detail {
            background-color: $neutral-10;
            border-radius: 8px;

            .date-title {
                margin: 0;
            }

            .table-header {
                background-color: $neutral-10;
                border: 1px solid #e9eaec;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            .last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .list-product-item {
                background-color: white;
            }

            .sum-total {
                padding: 16px 0;
            }

            .plan-index {
                @include text-style(500, rem(16), rem(24), $gray-900);
            }
        }
    }

    .detail-actions {
        border-top: 1px solid #eaecf0;
    }

    .quote-items-list {
        display: none;
    }

    .plan-list {
        font-weight: 500;
        font-size: 20px;
        color: #101828;
        margin-bottom: 12px;
    }

    .plan-total {
        .sum-total {
            .sum-total-title {
                @include text-style(500, rem(16), rem(20), #101828);
            }

            .sum-total-value {
                @include text-style(500, rem(16), rem(20), $gray-500);
            }
        }
    }

    .payment-plans {
        .ant-col {
            width: 100%;
        }
        .section-header {
            .payment-plans-title {
                @include text-style(700, rem(18), rem(24), $gray-900);
            }

            .add-payment-plans {
                @include text-style(700, rem(16), rem(24), var(--primary));
            }
        }

        .plan-index {
            @include text-style(500, rem(16), rem(24), $gray-900);
        }

        .plan-container {
            background-color: $neutral-10;
            border-radius: 8px;
            .date-label {
                @include text-style(400, rem(16), rem(24), $neutral-500);
            }

            .table-header {
                background-color: $neutral-10;
                border: 1px solid #e9eaec;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            .last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }

        .list-plan-item {
            @include max-size(577px) {
                padding: 0 3px;
                .ant-form-item {
                    margin-bottom: 0;
                }
            }
            padding-top: 10px;
            margin-left: 0 !important;
            margin-right: 0 !important;
            background-color: $white;
        }

        .add-plan-item-btn {
            color: $primary;
            background-color: $secondary;
            @include text-style(700, rem(16), rem(24), var(--primary));
        }

        .plan-term-conditions {
            .condition-index {
                width: 8px;
                min-width: 8px;
                height: 8px;
                min-height: 8px;
                border-radius: 50%;
                background-color: $gray-900;
            }

            .text {
                @include text-style(500, rem(16), rem(20), #101828);
            }
        }
    }
}

.quote-page-wrapper {
    @include max-size(577px) {
        .filter-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    .search-item {
        margin-right: 30px;
    }

    .table-wrapper {
        .ant-table-row {
            cursor: pointer;
        }

        .quotes-table {
            .status {
                &-0 {
                    color: $black100;
                }
                &-1 {
                    color: $ant-active-text;
                }
                &-2,
                &-3 {
                    color: $success-700;
                }
                &-4 {
                    color: $error-700;
                }
            }
        }
    }
}

.quote-tooltip {
    .ant-tooltip-inner {
        .ant-btn-link {
            color: $white;
        }
    }
}

.ant-dropdown-menu-title-content {
    font-size: 16px;
    font-weight: 500;
}

@include max-size(768px) {
    .quote-detail {
        height: 100%;
        padding-bottom: rem(94);
        overflow-y: auto;
        border-bottom: none;
        .block-heading {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .date-container {
                display: flex;
                flex-direction: column;
            }
        }

        .details-section {
            .user-info-col {
                width: 100%;
            }

            &.quote-items-table {
                display: none;
            }
        }

        .quote-items-list {
            display: flex;
            flex-direction: column;
            .ant-col {
                &.title {
                    span {
                        @include text-style(500, rem(16), rem(20), #101828);
                    }
                }

                &.value {
                    display: flex;
                    justify-content: flex-end;
                    span {
                        @include text-style(500, rem(16), rem(20), $gray-500);
                    }
                }
            }
        }

        .detail-actions {
            background-color: $white;
            width: 100%;
            position: fixed;
            bottom: 0;
            padding: 24px;
        }

        .sum-total {
            .sum-total-title {
                @include text-style(500, rem(16), rem(20), #101828);
            }

            .sum-total-value {
                @include text-style(500, rem(16), rem(20), $gray-500);
            }
        }
    }
}

.table-clickable-link,
.table-clickable-link:hover {
    white-space: inherit;
    color: #fff !important;
    text-decoration: underline;
    font-size: 14px !important;
}

@media print {
    .print:hidden,
    .no-print {
        display: none !important;
        overflow: hidden;
        height: 0;
    }
}

.quote-information {
    .ant-row {
        @include max-size(577px) {
            flex-direction: column;
        }
    }
}

.table-header-none {
    @include max-size(577px) {
        display: none;
    }
}

.item-custom {
    @include max-size(577px) {
        flex-direction: column;
        .ant-form-item {
            width: 100%;
            margin-bottom: 0;
        }
    }

    @include min-size(578px) {
        .ant-form-item-label > label {
            display: none;
        }
    }
}

.product-custom {
    margin-top: 1.5rem;

    @include min-size(578px) {
        .ant-form-item-label > label {
            display: block;
        }
    }
}

.total-wrapper {
    @include max-size(577px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-left: 1rem;
        padding-right: 1rem !important;
        border-top: 1px solid $neutral-10;
    }
}

.total-price {
    margin-bottom: 1rem;
    @include min-size(577px) {
        display: none;
    }
}

.frequency-custom,
.total-custom {
    .ant-form-item-control-input {
        @include max-size(577px) {
            background-color: $neutral-10;
            height: 44px;
            line-height: 42px;
            border-radius: 6px;
        }
    }
}

.plan-container {
    .ant-form-item-label {
        @include min-size(578px) {
            display: none;
        }
    }
}

.button-delete-custom {
    margin-top: 0;
    @include max-size(577px) {
        display: flex;
        justify-content: flex-end;
    }
}
