.tableWrapper {
    height: 500px; /* Adjust this height as needed */
    overflow-y: auto;
}
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6); // semi-transparent background
    backdrop-filter: blur(5px); // adds the blur effect
    z-index: 1000; // ensure it's on top of other content
}
